import React, { Component } from 'react';
import _ from 'lodash';

import { Link } from 'react-router-dom';

import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import { encode } from '../utils';

import Slide from './Slide';

class Slides extends Component {
  constructor(props) {
    super(props);
    this.state = { skippedSlides: [], nextSlide: false };
  }

  onMouseEnter(slideId) {
    if (window.isDragging) { return; }
    this.props.goto(slideId);
  }

  onMouseLeave() {
    this.props.reset();
  }


  onMouseEnterBranch(action, nextSlide, slideId) {
    const slides = this.props.poll.slides;
    const skippedSlides = [];
    const slideIdx = _.findIndex(slides, ({ _id }) => _id === slideId);
    let isSkipped = false;

    if (nextSlide === null) { return; }

    if (nextSlide === -1) {
      slides.some((slide) => {
        if (slideId === slide._id) {
          /* Start */
          isSkipped = true;
          return false;
        }
        if (isSkipped) {
          skippedSlides.push(slide._id);
        }
      })      
    } else {
      slides.some((slide, idx) => {
        if (nextSlide === slide._id) {
          /* End */
          if (idx < slideIdx) {
            // return false;
            isSkipped = true;
            return false;
          } else {
            return true;
          }
        }
        if (slideId === slide._id) {
          /* Start */
          isSkipped = true;
          return false;
        }
        if (isSkipped) {
          skippedSlides.push(slide._id);
        }
      })
    }
    this.setState({ nextSlide, skippedSlides });
  }

  onMouseLeaveBranch(action, nextSlide, slideId) {
    this.setState({ nextSlide: false, skippedSlides: [] });
  }

  render() {
    return (
    <div className="slide-form-wrapper">
      <div className="slides slide-form">
        { this.props.poll.slides.map((slide, idx) => (
          <Slide 
            onMouseEnter={() => this.onMouseEnter(slide._id)}
            onMouseLeave={this.onMouseLeave.bind(this)} 
            key={slide._id}
            accountId={this.props.accountId}
            pollId={this.props.pollId}
            idx={idx}
            slideCount={this.props.poll.slides.length}
            slides={this.props.poll.slides}
            delete={this.props.delete}
            update={this.props.update}
            duplicate={this.props.duplicate}
            active={this.props.currentIdx === idx}
            onMouseEnterBranch={this.onMouseEnterBranch.bind(this)}
            onMouseLeaveBranch={this.onMouseLeaveBranch.bind(this)}
            highlight={this.state.nextSlide ? slide._id === this.state.nextSlide : false}
            lowlight={this.state.skippedSlides.length ? this.state.skippedSlides.indexOf(slide._id) !== -1 : false}
            {...slide}
          />
        )) }
      </div>

      <Link className="add-slide" to={`/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}/s/create`}>Add Slide</Link>

    </div>);
  }
}

export default DragDropContext(HTML5Backend)(Slides);
