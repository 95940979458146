import React, { Component } from 'react';
// import { bindActionCreators } from 'redux';
// import { connect } from 'react-redux';

import $ from 'jquery';
import _ from 'lodash';

// import * as AccountActions from '../actions/Accounts';
// import * as FlashNotificationActions from '../actions/FlashNotifications';

import HeaderButtons from '../components/HeaderButtons';
import ColorPicker from '../components/ColorPicker';
import Toggle from '../components/Toggle';
import Switch from '../components/Switch';
import PositionInput from '../components/PositionInput';
import AlignmentInput from '../components/AlignmentInput';
import SmsSettingsVisualDisplay from '../components/SmsSettingsVisualDisplay';
import Tooltip from '../components/Tooltip';
import { NameInput, CCRecipientsInput } from '../components/SimpleInputs';
import WysiwygInput from './WysiwygInput';
import IconSelector from '../components/IconSelector';
import SectionHeader from '../components/SectionHeader';
import CharCount from './CharCount';

import { AuthSubmitButton } from './Buttons';
import { FontSizeSlider } from './Sliders';
import { defaultSmsDisplaySettings } from '../settings';

import SingleImageUploader from './SingleImageUploader';

export default class DisplaySettingsForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...defaultSmsDisplaySettings, ...props.settings }

    this.setInitialState();

    this.scroll = _.throttle(this.scroll.bind(this), 0, { leading: true, trailing: true });
  }

  // componentDidMount() {
  //   $('.foreground').on('scroll.account', this.scroll);
  //   this.scroll();
  // }

  // componentWillUnmount() {
  //   $('.foreground').off('scroll.account', this.scroll)
  // }

  scroll() {
    // let $el = $('.account-display .visual-display').first();
    
    // let zoom = $('html').css('zoom') || 1;
    // zoom = parseFloat(zoom);

    // let t = $('form.account-settings')[0].getBoundingClientRect().y - $('.foreground')[0].getBoundingClientRect().y * zoom;
    // let st = 0;

    // let b = $('form.account-settings').height();
    // let atBottom = ((st - t) + $el.height()) >= b;

    // if ( (t) < 10 && !atBottom ) {
    //   $el.css('transform', `translateY(${-t + 20}px)`);
    // } else if (atBottom) {
    //   $el.css('transform', `translateY(${b - $el.height()}px)`);
    // } else {
    //   $el.css('transform', `translateY(0px)`);
    // }  
  }

  setInitialState() {
    const settings = this.props.settings || defaultSmsDisplaySettings;

    this.initialState = { ...this.state, content: _.cloneDeep(settings.content) };

    setTimeout(() => {
      this.forceUpdate();
    }, 1000);
  }

  componentDidUpdate(prevProps, prevState) {
    console.log('component did update');
  }

  onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    this.updateSettings();
  }

  onContentChange(e) {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  }

  onValidate(e) {
    console.log('on validate');
  }

  setRef() {
    console.log('set ref called');
  }

  updateSettings() {
    const account = {};
    account._id = this.props.account._id;
    account.smsDisplaySettings = { ...this.state };

    const formData = new FormData();

    formData.append('account', JSON.stringify(account));

    this.props.updateAccountSmsDisplaySettings(account._id, formData, 'Your changes have been saved.').then(() => this.setInitialState());
  }

  cancel() {
    this.setState({ ...this.initialState });
    this.forceUpdate();
    this.props.flash('Your changes have been cancelled.');
  }

  render() {
    const hasChanged = JSON.stringify(this.state) !== JSON.stringify(this.initialState);

    let title = (<SectionHeader
      title=<span><i className="fas fa-sms"/>SMS Design Settings</span>
      subtitle="Control the appearance of your sms campaigns."
      className="no-margin center"
    />);

    if (this.props.hideTitle) {
      title = null;
    }

    let customLinkToggle = null;
    const plan = this.props.account.plan;
    if (plan && plan.allowSmsCustomization) {
    customLinkToggle = <div style={{ marginBottom: 50 }}>
      <div>
        <label>Custom Survey Link<Tooltip>If you have an embedded survey on your website, you can paste a link to it here. Note that no metadata will be passed to this custom link.</Tooltip></label>
        <div className="inline-toggle">
          <Toggle 
            active={this.state.showCustomLinkInput}
            onChange={(value) => {
              if (!value) {
                this.setState({ customLink: '' });
              }
              this.setState({ showCustomLinkInput: value });
            }}
          />
          <div className="subtle">Link to an embedded survey that you have configured.</div>
        </div>
      </div>
      { this.state.showCustomLinkInput && <NameInput
        onChange={(e) => this.setState({ customLink: e.target.value }) }
        onValidate={this.onValidate.bind(this)}
        hideLabel={true}
        type="text"
        placeholder="www.yourwebsite.com/pages/survey"
        name="sender"
        value={this.state.customLink}
        ref={this.setRef.bind(this)}
        noValidate={true}
        optional={false}
        maxlength={60}
      /> }
    </div>
    }

    return (
      <div className="account-display" style={this.props.style || {}}>
        { title }
        <div className="split">
          <div>
            <form 
              onSubmit={this.onSubmit.bind(this)}
              className={`account-settings ${this.props.loading ? 'loading' : ''}`}
            >

              <div className="card">

                <div className="card-divider" style={{ marginTop: -15 }}>
                  <h6>Content</h6>
                </div>

                <div style={{ marginBottom: this.props.account.shop ? 25 : 50 }}>
                  <label>SMS Message</label>
                  <textarea
                    className="textarea"
                    name="message"
                    label="Message"
                    style={{ height: 150 }}
                    maxlength={125}
                    optional={false}
                    onChange={this.onContentChange.bind(this)}
                    value={this.state.message}
                  />
                  <CharCount
                    length={this.state.message ? this.state.message.length : 0}
                    maxlength={125}
                  />
                </div>

                { customLinkToggle }

                <div className={`actions ${hasChanged ? 'active' : ''}`}>
                  <AuthSubmitButton 
                    title="Save Changes"
                    disabled={!hasChanged}
                  />
                  <button 
                    className="cancel"
                    disabled={!hasChanged}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.cancel();
                    }}
                  >Cancel</button>
                </div>
              </div>
            </form>
          </div>

          <SmsSettingsVisualDisplay
            {...this.state}
            account={this.props.account}
            poll={this.props.poll}
          />
        </div>

        <HeaderButtons>
          <button 
            disabled={!hasChanged}
            onClick={this.onSubmit.bind(this)}>{ this.props.loading ? 'Saving...' : 'Save Changes' }</button>

          <button 
            className="cancel"
            disabled={!hasChanged}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.cancel();
            }}
          >Cancel</button>
        </HeaderButtons>
      </div>
    );
  }
}
