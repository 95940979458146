import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as SlideActions from '../actions/Slides';
import * as FlashNotificationActions from '../actions/FlashNotifications';

import $ from 'jquery';
import { default as ReactSelect } from 'react-select';

import { NameInput } from './SimpleInputs';
import WysiwygInput from './WysiwygInput';
import Toggle from './Toggle';
import Switch from './Switch';

import AnswersInput from './AnswersInput';
import BinaryAnswersInput from './BinaryAnswersInput';
import ImageChoiceAnswersInput from './ImageChoiceAnswersInput';
import RangeInput from './RangeInput';
import SliderInput from './SliderInput';
import FormInputs from './FormInputs';
import InlineInput from './InlineInput';
import ActionInputs from './ActionInputs';

import { AuthSubmitButton } from './Buttons';

import AuthForm from './AuthForm';

import Tooltip from './Tooltip';
import Confirm from './Confirm';

import HeaderButtons from './HeaderButtons';
import NextSlideInput from './NextSlideInput';

import SatisfactionNextSlideInput from './SatisfactionNextSlideInput';
import StarRatingNextSlideInput from './StarRatingNextSlideInput';
import SingleImageUploader from './SingleImageUploader';
import SlideBankButton from './SlideBankButton';

import CharacterLimitInput from './CharacterLimitInput';
import MetafieldKeyInput from './MetafieldKeyInput';
import FormatInput from './FormatInput';
import DateInput from './DateInput';

import _ from 'lodash';

import { getSlideLabel, getSlideIcon, slideTypes, encode, stripTags, previewSlides } from '../utils';

const applyTheme = (theme) => ({
  ...theme,

  colors: {
    ...theme.colors,
    primary: "#2167f5",
    danger: "#F26c57",
  }
});

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    background: '#fff',
    borderColor: '#d5dee6',
    minHeight: '33px',
    height: '33px',
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    height: '33px',
    padding: '0 12px 0 6px'
  }),

  input: (provided, state) => ({
    ...provided,
    margin: '0px',
  }),

  indicatorSeparator: state => ({
    display: 'none',
  }),

  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '33px',

  }),
}

class Option extends Component {
  render() {
    const previewing = this.props.preview === this.props.value;

    return (
      <div
        className={`option ${this.props.active ? 'active' : ''}`}
        onClick={() => { if (this.props.onChange) {
          this.props.onChange(this.props.value) 
        }}}
      >
        <div>

          <i className={`fa ${ this.props.icon || 'fa-star' }`} />

          { this.props.label }

          <div 
            className={`preview ${previewing ? 'active' : ''}`}
            onClick={(e) => {
              if (this.props.onPreview) {
                e.stopPropagation();
                e.preventDefault();
                this.props.onPreview(this.props.value);
              }
            }}
          >{ previewing ? 'Previewing' : 'Preview' }</div>
        </div>
      </div>
    );
  }
}

class SlideTypeSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onChange(value) {
    this.setState({ hover: false });
    this.props.onChange(value);
    this.props.cancelPreview();
  }

  render() {
    const type = this.props.type;
    const label = getSlideLabel(type);
    const icon = getSlideIcon(type);

    let cancelPreview = null;

    if (this.props.preview) {
      cancelPreview = (<div className="cancel-preview">
        <span>Previewing: <em><i className={`fas ${ getSlideIcon(this.props.preview)}`} />{ getSlideLabel(this.props.preview) }</em></span>
        <button onClick={this.props.cancelPreview}>Cancel</button>
      </div>);
    }

    return (
      <div className="slide-type-select">
        <div
          className={`container ${this.state.hover ? 'hover' : ''}`}
          // onMouseEnter={() => this.setState({ hover: true })}
          // onMouseLeave={() => this.setState({ hover: false})}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();

            setTimeout(() => {
              $(window).one('click.slide-type-select', () => {
                this.setState({ hover: false });
              });
            }, 0);

            this.setState({ hover: !this.state.hover }) 
          }}
        >
          <div className="active-option">
            <Option active={true} type={type} label={label} value={type} icon={icon} onChange={this.props.cancelPreview}/>
          </div>
          <div className="options">
          { slideTypes.map(({ label, value, icon }) => (<Option key={label} active={type === value} label={label} value={value} icon={icon} onChange={this.onChange.bind(this)} onPreview={(value) => {
             this.setState({ hover: false }); this.props.onPreview(value); console.log('called on preview'); }} cancelPreview={this.props.cancelPreview} preview={this.props.preview} />)) }
          </div>
        </div>

        { cancelPreview }
      </div>
    );
  }
}

function needsConfirmation (currentType, nextType, answers) {
  /* Skipping for now */
  return false;

  if ([
    'short-answer',
    'long-answer',
    'file-upload',
    'date',
    'email-capture',
    'copy',
    'satisfaction'
  ].indexOf(currentType) !== -1) { return false; }
  if (!answers) { return false }

  if (answers.length && (JSON.stringify(answers) !== JSON.stringify(previewSlides[currentType].answers))) {
    return true;
  }

  return false;
}

function needsAnswersReset(currentType, nextType) {
  const allowedTypes = [
    'question',
    'checkbox',
    'dropdown',
    'inline-multiple-choice',
    'rank'
  ];
  if ((allowedTypes.indexOf(currentType) !== -1) && (allowedTypes.indexOf(nextType) !== -1)) {
    return false;
  }
  return true;
}

function getIcon(val) {
  if (val === 'end') {
    return 'fa-stop';
  }
  if (val === 'add-customer-tag' || val === 'add-order-tag') {
    return 'fa-tag';
  }
  if (val === 'add-customer-metafield' || val === 'add-order-metafield') {
    return 'fa-cube'
  }
  if (val === 'redirect') {
    return 'fa-square-arrow-up-right';
  }
  if (val === 'send-email') {
    return 'fa-envelope';
  }

  return 'fa-diamond-turn-right';
}

const selectSlideTypes = [];
Object.keys(previewSlides).forEach((key) => {
  const slide = previewSlides[key];
  if (slide.answers && slide.answers.length) {
    selectSlideTypes.push(slide.type);
  }
});
  
/* Want to select the first option but dont want to select it if it's disabled... should be a better way forward here... */
class Select extends Component {
  componentDidMount() {
    if (this.props.options && this.props.options.length && !this.props.value) {
      let value;

      this.props.options.some((option) => {
        if (!option.disabled) {
          value = option.value;
        }
        return !option.disabled;
      })

      if (value) {
        this.props.onChange({ target: { value }});
      }
    }
  }

  render() {
    return <div className="select-wrapper small"><select onChange={this.props.onChange.bind(this)} value={this.props.value}>
      { this.props.options.map(({ title, value, disabled }) => {
        return (<option disabled={disabled} value={value || title}>{ title }</option>);
      })}
    </select></div>
  }
}

class RuleInput extends Component {
  render() {
    if (!_.find(this.props.ruleOptions, ({ value }) => this.props.value.rule === value)) {
      return null;
    }

    let ruleSelect = <Select
      options={this.props.ruleOptions}
      onChange={(e) => {
        const val = e.target.value;
        const idx = 'rule';
        this.props.onChange(null, 'ruleValue');
        this.props.onChange(val, idx);
      }}
      value={this.props.value['rule']}
    />

    let ruleValueInput = <div className="input">
      <input
        value={this.props.value['ruleValue'] || ''}
        onChange={(e) => {
          const val = e.target.value;
          const idx = 'ruleValue';
          this.props.onChange(val, idx);
        }}
      />
    </div>

    if (selectSlideTypes.indexOf(this.props.slide.type) !== -1) {
      const handles = this.props.slide.answers.map(({ title, handle }) => title || handle);
      if (!this.props.value['ruleValue'] || handles.indexOf(this.props.value['ruleValue']) !== -1) {
        ruleValueInput = <Select
          key={this.props.value.rule}
          options={this.props.slide.answers.map((answer) => {
            let disabled = false;
            // if (this.props.selectedValues.indexOf(answer.handle) !== -1) {
              // disabled = true;
            // }
            return { title: answer.title, value: answer.title || answer.handle, disabled }
          })}
          onChange={(e) => {
            const val = e.target.value;
            const idx = 'ruleValue';
            console.log('updating rule value');
            this.props.onChange(val, idx);
          }}
          value={this.props.value['ruleValue']}
        />;
      }
    }

    if (['includes-any', 'includes-all', 'excludes-any', 'excludes-all'].indexOf(this.props.value.rule) !== -1) {
      const options = this.props.slide.answers.map(({ title, handle }) => {
        return { value: title || handle, label: title || handle }
      });

      let listValue = this.props.value.ruleValue || [];
      listValue = listValue.map((val) => ({ value: val, label: val }));

      ruleValueInput = <ReactSelect
        value={listValue}
        isMulti
        isClearable={false}
        options={options}
        name="react-select"
        className={'react-select'}
        theme={applyTheme}
        styles={customStyles}
        onChange={(val = []) => {
          if (val === null) {
            val = [];
          }
          this.props.onChange(val.map(({ value }) => value), 'ruleValue');
        }}
      />
    }

    if (this.props.value['rule'] === 'submitted') {
      ruleValueInput = null;
    }

    return (<div className="logic-rule"><span style={{ display: 'inline' }}>{this.props.idx === 0 ? 'when' : 'or'} {['reward', 'copy'].indexOf(this.props.slide.type) === -1 ? 'answer' : 'slide'}</span>{ruleSelect}<div className="end-wrapper">{ruleValueInput}<div onClick={() => this.props.onRemove(this.props.idx)}className="remove"><i className="fas fa-close" /></div></div></div>)
  }
}

class ActionInput extends Component {
  render() {
    let actionOptions = [
      { title: 'end survey', value: 'end' },
      { title: 'skip to', value: 'skip' },
      { title: 'redirect to', value: 'redirect' },
      // { title: 'send email', value: 'send-email' }
    ];
    if (this.props.account.shop) {
      actionOptions.push({ title: 'add customer tag', value: 'add-customer-tag' })
      actionOptions.push({ title: 'add order tag', value: 'add-order-tag' })
      actionOptions.push({ title: 'add customer metafield', value: 'add-customer-metafield' });
      actionOptions.push({ title: 'add order metafield', value: 'add-order-metafield' });
    }

    let actionSelect = <Select
      options={actionOptions}
      onChange={(e) => {
        const val = e.target.value;
        const idx = 'action';

        this.props.onChange(null, 'actionValue');
        this.props.onChange(val, idx);
      }}
      value={this.props.value['action']}
    />

    let slideOptions = [];
    let notDisabled = [];
    // const slideIdx = _.findIndex(this.props.slides, ({ _id }) => _id == this.props.slideId);
    this.props.slides.forEach((slide, idx) => {
      // if (slide._id === this.props.slideId) { return; }
      let disabled = false;
      if (slide._id === this.props.slideId) { disabled = true; }
      if (!slide.title && !slide.handle) { disabled = true }
      // if (idx < slideIdx) { disabled = true }

      if (!disabled) { notDisabled.push(slide) }
      slideOptions.push({ title: slide.handle || slide.title, value: slide._id, disabled });
    });

    if (notDisabled.length === 0) {
      slideOptions = [{ title: 'No slides available', value: 'no-slides-available', disabled: true }]
    }


    if (!_.find(slideOptions, ({ value }) => value === this.props.value['actionValue'] )) {
      slideOptions.push({ title: '[Slide Removed]', value: this.props.value['actionValue'], disabled: true });
    }

    let actionValueInput = <Select
      options={slideOptions}
      onChange={(e) => {
        const val = e.target.value;
        const idx = 'actionValue';
        this.props.onChange(val, idx);
      }}
      value={this.props.value['actionValue']}
    />

    if (this.props.value['action'] === 'end') {
      actionValueInput = null;
    }
    if ((this.props.value['action'] === 'add-order-tag') || (this.props.value['action'] === 'add-customer-tag')) {
      actionValueInput = <div className="input">
        <input
          key={this.props.value['action']}
          value={this.props.value['actionValue']}
          placeholder="tag"
          onChange={(e) => {
            const val = e.target.value;
            const idx = 'actionValue';
            this.props.onChange(val, idx);
          }}
        />
      </div>
    }
    if (this.props.value['action'] === 'redirect') {
      actionValueInput = <div className="input">
        <input
          key={this.props.value['action']}
          value={this.props.value['actionValue']}
          placeholder="https://www.yourwebsite.com"
          onChange={(e) => {
            const val = e.target.value;
            const idx = 'actionValue';
            this.props.onChange(val, idx);
          }}
        />
      </div>
    }
    if (this.props.value['action'] === 'send-email') {
      actionValueInput = <div className="input">
        <input
          key={this.props.value['action']}
          value={this.props.value['actionValue']}
          placeholder="EMAIL CONTENT?"
          onChange={(e) => {
            const val = e.target.value;
            const idx = 'actionValue';
            this.props.onChange(val, idx);
          }}
        />
      </div>
    }
    if (this.props.value['action'] === 'add-customer-metafield' || this.props.value['action'] === 'add-order-metafield') {
      let mfInfo, mfValue;
      if (this.props.value['actionValue'] !== null) {
        [ mfInfo, mfValue ] = this.props.value['actionValue'].split('|XX|');
      }

      actionValueInput = <div className="input double">
        <input
          placeholder="namespace.key"
          className="long"
          key="mfInfo"
          value={mfInfo}
          onChange={(e) => {
            const val = `${e.target.value || ''}|XX|${mfValue || ''}`;
            const idx = 'actionValue';
            this.props.onChange(val, idx);
          }}
        />
        <input
          placeholder="value"
          className="short"
          key="mfValue"
          value={mfValue}
          onChange={(e) => {
            const val = `${mfInfo || ''}|XX|${e.target.value || ''}`;
            const idx = 'actionValue';
            this.props.onChange(val, idx);
          }}
        />
      </div>
    }
    return (<div className="bottom logic-action"><span>{ this.props.idx === 0 ? 'then' : 'and' }</span>{actionSelect}<div className="end-wrapper">{ actionValueInput }<div onClick={() => this.props.onRemove(this.props.idx)}className="remove"><i className="fas fa-close" /></div></div></div>);
  }
}

class LogicRow extends Component {
  render() {
    let orDivider = <div className="logic-or-divider"></div>
    if (this.props.idx === 0) {
      orDivider = null;
    }
    
    let addRule = <div className="add-logic-condition">
      <div onClick={() => {
        const rules = [ ...this.props.value.rules ]
        rules.push({ ...defaultRule });
        this.props.onChange('rules', rules);
      }}>Add condition<i className="fas fa-plus" /></div></div>;
    let addAction = <div className="add-logic-action">
      <div onClick={() => {
        const actions = [ ...this.props.value.actions ]
        actions.push({ ...defaultAction });
        this.props.onChange('actions', actions);
      }}>Add action<i className="fas fa-plus" /></div></div>;

    let icons = [];
    this.props.value.actions.forEach(({ action }) => {
      icons.push(getIcon(action));
    });
    icons = _.uniq(icons);

    return (
      <div>
        { orDivider }
        <div className="row stacked">
          <div className="icon-group">
            { icons.map(icon => {
              return <i className={`fas ${icon}`} />
            }) }
          </div>
          <div className="logic-rules">
            { this.props.value.rules.map((rule, idx) => {
              return <RuleInput
                idx={idx}
                value={rule}
                slide={this.props.slide}
                ruleOptions={this.props.ruleOptions}
                onRemove={(idx) => {
                  const rules = [ ...this.props.value.rules ];
                  rules.splice(idx, 1);
                  this.props.onChange('rules', rules);
                  if (rules.length === 0 && this.props.value.actions.length === 0) {
                    this.props.onRemove(this.props.idx);
                  }
                }}
                onChange={(value, key) => {
                  const rules = [ ...this.props.value.rules ];
                  rules[idx][key] = value;
                  this.props.onChange('rules', rules);
                }}
              />
            })}
            {addRule}
          </div>
          <div className="logic-actions">
            { this.props.value.actions.map((action, idx) => {
              return <ActionInput
                idx={idx}
                value={action}
                account={this.props.account}
                slides={this.props.slides}
                slideId={this.props.slideId}
                onRemove={(idx) => {
                  const actions = [ ...this.props.value.actions ];
                  actions.splice(idx, 1);
                  this.props.onChange('actions', actions);
                  if (actions.length === 0 && this.props.value.rules.length === 0) {
                    this.props.onRemove(this.props.idx);
                  }
                }}
                onChange={(value, key) => {
                  const actions = [ ...this.props.value.actions ];
                  actions[idx][key] = value;
                  this.props.onChange('actions', actions)
                }}
              />
            })}
            {addAction}
          </div>
        </div>
      </div>
    );
  }
}

const defaultRule = { rule: 'submitted', ruleValue: null };
const defaultAction = { action: 'end', actionValue: null };

class SlideLogicInput extends Component {
  constructor(props) {
    super(props);

    const inputs = props.inputs;
    const answers = inputs.answers;
    let value = [ ...props.value ] || [{ rules: [{ ...defaultRule }], actions: [{ ...defaultAction }] }];
    // let deprecatedValue = [];

    // if (inputs.nextSlide) {
    //   if (inputs.nextSlide === -1) {
    //     deprecatedValue.push({ rules: [{ rule: 'submitted', ruleValue: null }], actions: [{action: 'end', actionValue: null }] });
    //   } else {
    //     deprecatedValue.push({ rules: [{ rule: 'submitted', ruleValue: null }], actions: [{action: 'skip', actionValue: inputs.nextSlide }] });
    //   }
    // }

    // answers.forEach((answer) => {
    //   if (answer.nextSlide) {
    //     if (answer.nextSlide === -1) {
    //       deprecatedValue.push({ rules: [{ rule: '=', ruleValue: answer.title || answer.handle }], actions: [{ action: 'end', actionValue: null }] });
    //     } else {
    //       deprecatedValue.push({ rules: [{ rule: '=', ruleValue: answer.title || answer.handle }], actions: [{ action: 'skip', actionValue: answer.nextSlide }] });
    //     }
    //   }
    // });

    // if (deprecatedValue.length && !props.value.length) {
    //   const groups = _.groupBy(deprecatedValue, ({ actions }) => JSON.stringify(actions));
    //   const groupedValue = [];

    //   Object.keys(groups).forEach((key) => {
    //     const group = groups[key];
    //     const actions = group[0].actions;
    //     let joinedRules = [];

    //     group.forEach(({ rules }) => {      
    //       joinedRules = [...joinedRules, ...rules];
    //     });
    //     groupedValue.push({ actions, rules: joinedRules });
    //   });
    //   this.state = { value: groupedValue };      
    // } else {
      this.state = { value };
    // }
  }

  removeRow(idx) {
    let value = [ ...this.state.value ];
    value.splice(idx, 1);
    this.setState({ value }, () => {
      this.props.onChange(value);
    });
  }

  renderRows() {
    // const selectedValues = [];
    // this.state.value.forEach((val) => {
    //   if (val.ruleValue) {
    //     selectedValues.push(val.ruleValue);
    //   }
    // });
    return (<div>
      { this.state.value.map((val, i) => <LogicRow
        idx={i}
        value={val}
        slideId={this.props.slideId}
        slide={this.props.slide}
        slides={this.props.slides}
        account={this.props.account}
        poll={this.props.poll}
        ruleOptions={this.props.ruleOptions}
        // selectedValues={selectedValues}
        inputs={this.props.inputs}
        onRemove={this.removeRow.bind(this)}
        onChange={(key, val) => {
          const value = [ ...this.state.value ];
          const row = value[i];
          row[key] = val;
          this.setState({ value });
          this.props.onChange(value);
        }}
      /> ) }
    </div>)
  }

  render() {
    return (
      <div>
       <div className="section-label logic-section"><i className="fas fa-code-branch" />Logic<Tooltip bottom={true}>Configure special logic and behavior settings for each answer option.</Tooltip></div>
       <div className="logic-input card" style={{ marginTop: 10 }}>
         { this.renderRows() }

         { this.state.value.length > 0 && <span><div className="logic-or-divider"></div><div className="row" style={{ lineHeight: '32px' }}><div className="icon-group"><i className="fas fa-play" /></div>All other answers will continue to the next slide.</div></span> }

         { <div><div
            className="logic-toggle"
            onClick={() => {
              const value = [ ...this.state.value, { rules: [ { rule: 'submitted', ruleValue: null } ], actions: [ { action: 'end', actionValue: null } ] }];
              this.setState({ value })
              this.props.onChange(value);
            }}><i className="fas fa-code-branch"/>Add Logic</div>{ this.state.value.length === 0 ? 'to configure special behaviors for this slide.' : '' }</div> }
       </div>
      </div>
    );
  }
}

export class SlideEditFormComponent extends AuthForm {
  constructor(props)  {
    super(props);

    let settings = this.props.slide.settings || {};

    // let showAdvancedOptions = (this.props.slide.handle !== this.props.slide.title) || settings.hidden || this.props.slide.nextSlide ? true : false;
    let showAdvancedOptions = false;

    let showRangeLabels = (this.props.slide.leftLabel || this.props.slide.rightLabel);

    this.hasSubtitle = this.props.slide.subtitle;

    this.state = {
      inputs: {
        title: this.props.slide.title,
        handle: this.props.slide.handle || stripTags(this.props.slide.title),
        subtitle: this.props.slide.subtitle,
        answers: this.props.slide.answers || [],
        actions: this.props.slide.actions || [],
        copy: this.props.slide.copy,
        placeholder: this.props.slide.placeholder,
        type: this.props.slide.type,
        rewardCode: this.props.slide.rewardCode,
        rewardCodeType: this.props.slide.rewardCodeType || 'static',
        rewardDiscountValue: this.props.slide.rewardDiscountValue,
        rewardDiscountType: this.props.slide.rewardDiscountType,
        rewardDiscountExpiration: this.props.slide.rewardDiscountExpiration,
        rewardBanner: this.props.slide.rewardBanner,
        leftLabel: this.props.slide.leftLabel,
        rightLabel: this.props.slide.rightLabel,
        minDate: this.props.slide.minDate ? new Date(this.props.slide.minDate) : undefined,
        maxDate: this.props.slide.maxDate ? (this.props.slide.maxDate === 'today' ? 'today' : new Date(this.props.slide.maxDate)) : undefined,
        singleFileOnly: this.props.slide.singleFileOnly,
        showTitle: settings.showTitle,
        showResults: settings.showResults,
        randomizeAnswers: settings.randomizeAnswers,
        reverseOrder: settings.reverseOrder,
        showResponses: settings.showResponses,
        autoApproveResponses: settings.autoApproveResponses,
        sortBy: settings.sortBy,
        optional: settings.optional,
        hidden: settings.hidden,
        disableSentimentAnalysis: settings.disableSentimentAnalysis,
        hideConfetti: settings.hideConfetti,
        showConfetti: settings.showConfetti,
        charLimits: settings.charLimits,
        showAnswerTitle: settings.showAnswerTitle,
        numColumns: settings.numColumns,
        format: settings.format,
        hideMonth: settings.hideMonth,
        hideYear: settings.hideYear,
        hideDay: settings.hideDay,
        dateFormat: settings.dateFormat,
        shopifyMetafieldConfig: settings.shopifyMetafieldConfig,
        nextSlide: this.props.slide.nextSlide,
        formInputs: this.props.slide.formInputs || [],
        image: this.props.slide.image,
        responseLimit: this.props.slide.responseLimit || 1,
        responseLimitType: this.props.slide.responseLimitType,
        logic: this.props.slide.logic || []
        // terminal: settings.terminal
      },
      showRangeLabels: showRangeLabels,
      showAdvancedOptions: showAdvancedOptions,
      showResponsesInput: settings.showResponses,
      showHandleInput: this.props.slide.handle !== stripTags(this.props.slide.title),
      valid: undefined,
      showConfirm: false
    };

    /* Hide the countries array for UI convenience */
    if (this.props.slide.type === 'country') {
      this.state.inputs.answers = [];
    }

    this.prevType = this.props.slide.type;
    this.inputStates = {};
    this.setInitialState();
  }

  submitAction() {
    const type = this.state.inputs.type;

    if (type === 'form' && this.state.inputs.formInputs.length === 0) {
      return this.props.flash('Please enter form inputs if you want to create a form slide.');
    }

    // if (this.hasModifiedLogic) {
    //   this.props.flash('Invalid logic detected. Please fill out all logic inputs appropriately.');
    // }

    // if (!this.props.poll.isVisible) {
      this.submit()
    // } else {
      // this.setState({ showConfirmModal: true })
    // }
  }

  onDataUpdate() {
    this.setState({ localImage: undefined });
  }

  onChange(e) {
    const state = this.state.inputs;

    let oldAnswers;
    if (e.target.name === 'answers') {
      oldAnswers = _.cloneDeep(this.state.inputs.answers);
    }
    state[e.target.name] =  e.target.value;

    if ((this.prevType === this.state.inputs.type) && (e.target.name === 'answers') && this.state.inputs.logic) {
      let oldHandles = oldAnswers.map(({ title, handle }) => title || handle);
      let newHandles = e.target.value.map(({ title, handle }) => title || handle);

      let hasChanged = false;
      let logic = this.state.inputs.logic;

      logic.forEach(({ rules, actions }, i) => {
        rules.forEach(({ rule, ruleValue }, j) => {
          const oldIdx = oldHandles.indexOf(ruleValue);
          if (oldIdx !== -1 && newHandles.indexOf(ruleValue) === -1) {
            logic[i].rules[j].ruleValue = newHandles[oldIdx];
          }
        })
      })
      if (hasChanged) {
        state['logic'] = logic;
      }
    }

    if (e.target.name === 'type') {
      /* Hack */
      setTimeout(() => {
        this.prevType = this.state.inputs.type;
      }, 100);
    }

    this.setState({ inputs: state }, () => {
      if (this.props.onChange) {
        this.props.onChange(this.state.inputs);
      }
    });
  }

  resetNextSlideState() {
    let answers;

    if (this.state.inputs.answers) {
      answers = _.cloneDeep(this.state.inputs.answers);
      answers.forEach((answer) => {
        delete answer.nextSlide;
      });
    }

    return answers;
  }

  submit() {
    const slide = {};

    slide.handle = this.state.inputs.handle || stripTags(this.state.inputs.title);
    if (!this.state.showHandleInput) {
      slide.handle = stripTags(this.state.inputs.title) || this.state.inputs.handle;
    }
    slide.title = this.state.inputs.title;
    slide.subtitle = this.state.inputs.subtitle;
    slide.copy = this.state.inputs.copy;
    slide.answers = _.cloneDeep(this.state.inputs.answers);
    slide.actions = this.state.inputs.actions;
    slide.placeholder = this.state.inputs.placeholder;
    slide.type = this.state.inputs.type;
    slide._id = this.props.slideId;
    slide.leftLabel = this.state.inputs.leftLabel;
    slide.rightLabel = this.state.inputs.rightLabel;
    slide.nextSlide = this.state.inputs.nextSlide;
    slide.minDate = this.state.inputs.minDate;
    slide.maxDate = this.state.inputs.maxDate;
    slide.singleFileOnly = this.state.inputs.singleFileOnly;
    slide.rewardCode = this.state.inputs.rewardCode;
    slide.rewardCodeType = this.state.inputs.rewardCodeType;
    slide.rewardDiscountValue = this.state.inputs.rewardDiscountValue;
    slide.rewardDiscountType = this.state.inputs.rewardDiscountType;
    slide.rewardDiscountExpiration = this.state.inputs.rewardDiscountExpiration;
    slide.rewardBanner = this.state.inputs.rewardBanner;
    slide.logic = this.state.inputs.logic;

    if (this.state.inputs.responseLimit !== undefined) {
      let limit = parseInt(this.state.inputs.responseLimit);
      if ((limit <= 0) || _.isNaN(limit)) {
        limit = 1;
      }
      slide.responseLimit = limit;
    }
    slide.responseLimitType = this.state.inputs.responseLimitType;

    /* Maybe need to clean this */
    if (this.state.inputs.formInputs) {
      const cleanFormInputs = [];

      this.state.inputs.formInputs.forEach((input) => {
        input.options = _.compact(input.options);
        if (input.options.length === 0 && (input.type === 'radio' || input.type === 'checkbox' || input.type === 'select' || input.type === 'inline-multiple-choice')) {
          console.log('Not including.');
        } else {
          cleanFormInputs.push(input);
        }
      });

      slide.formInputs = cleanFormInputs;
    }

    slide.settings = {};
    slide.settings.showResults = this.state.inputs.showResults;
    slide.settings.randomizeAnswers = this.state.inputs.randomizeAnswers;
    slide.settings.reverseOrder = this.state.inputs.reverseOrder;
    slide.settings.showResponses = this.state.inputs.showResponses;
    slide.settings.autoApproveResponses = this.state.inputs.autoApproveResponses;
    slide.settings.sortBy = this.state.inputs.sortBy;
    slide.settings.showTitle = this.state.inputs.showTitle;
    slide.settings.optional = this.state.inputs.optional;
    slide.settings.hidden = this.state.inputs.hidden;
    slide.settings.disableSentimentAnalysis = this.state.inputs.disableSentimentAnalysis;
    slide.settings.hideConfetti = this.state.inputs.hideConfetti;
    slide.settings.showConfetti = this.state.inputs.showConfetti;
    slide.settings.charLimits = this.state.inputs.charLimits;
    slide.settings.showAnswerTitle = this.state.inputs.showAnswerTitle;
    slide.settings.numColumns = this.state.inputs.numColumns;
    slide.settings.format = this.state.inputs.format;
    slide.settings.dateFormat = this.state.inputs.dateFormat;
    slide.settings.hideMonth = this.state.inputs.hideMonth;
    slide.settings.hideDay = this.state.inputs.hideDay;
    slide.settings.hideYear = this.state.inputs.hideYear;

    slide.settings.shopifyMetafieldConfig = this.state.inputs.shopifyMetafieldConfig;

    const formData = new FormData();

    if (this.state.localImage) {
      if (this.state.localImage === -1) {
        slide.image = -1;
      } else {
        formData.append('image', this.state.localImage);
      }
    }

    let answerImages = [];
    if (slide.type === 'image-choice') {
      slide.answers.forEach((answer, idx) => {
        if (answer.image) {
          answerImages.push({ key: `answer-image-${idx}`, value: answer.image });
          delete answer.image;
        }
      });
    }

    answerImages.forEach((image) => {
      formData.append(image.key, image.value);
    })
    formData.append('slide', JSON.stringify(slide));
    this.props.updateSlide(formData);
  }

  renderInputs() {
    const type = this.state.inputs.type;

    let answersInput = null;

    const slides = _.filter([ ...this.props.poll.slides ], ({_id, settings}) => _id !== this.props.slideId && !settings.hidden);

    /* Replace with binary input (only 2 answers) */
    if (type === 'question' || type === 'dropdown') {
      answersInput = (
      <div>
       <div className="section-label"><i className="fas fa-check-to-slot" />Answer Options<Tooltip>Set the value of each different answer you want presented to your survey participants.</Tooltip></div>
        <AnswersInput
          onChange={this.onChange.bind(this)}
          onValidate={this.onValidate.bind(this)}
          answers={_.cloneDeep(this.state.inputs.answers)}
          slides={slides}
          slideId={this.props.slideId}
          name="answers"
          key={type}
          showRules={type === 'question' || type === 'dropdown' ? true : false}
          showDynamicAnswer={true}
          className="card"
          ref={this.setRef.bind(this)}
        />
      </div>);
    }

    let answerRulesInput = null;
    if (type === 'checkbox' || type === 'inline-multiple-choice' || type === 'rank' || type === 'image-choice') {
      answerRulesInput = (
        <InlineInput
          label="Answer Rules"
          tooltip={<Tooltip>Example: Enter 2 if you want to require at least 2 responses before submitting.</Tooltip>}
          name="responseLimit"
          value={this.state.inputs.responseLimit}
          prefix={<span>Require <select onChange={this.onChange.bind(this)} name="responseLimitType" value={this.state.inputs.responseLimitType}><option value="at-least">at least</option><option value="exactly">exactly</option><option value="at-most">at most</option><option value="all">every</option></select></span>}
          suffix="answer(s) to be submitted."
          idx={0}
          hideInput={this.state.inputs.responseLimitType === 'all'}
          onChange={(name, value) => {
            if (value > this.state.inputs.answers.length) {
              value = this.state.inputs.answers.length;
            }
            this.onChange({ target: { name, value } }); 
          }}
        />
      );
      if (type === 'image-choice') {
        answerRulesInput = (
        <div>
          <InlineInput
            label="Answer Rules"
            tooltip={<Tooltip>Example: Enter "at least" 2 if you want to require at least 2 responses before submitting.</Tooltip>}
            name="responseLimit"
            value={this.state.inputs.responseLimit}
            prefix={<span>Require <select onChange={this.onChange.bind(this)} name="responseLimitType" value={this.state.inputs.responseLimitType}><option value="exactly">exactly</option><option value="at-least">at least</option><option value="at-most">at most</option><option value="all">every</option></select></span>}
            suffix="answer(s) to be submitted."
            idx={0}
            hideInput={this.state.inputs.responseLimitType === 'all'}
            onChange={(name, value) => {
              if (value > this.state.inputs.answers.length) {
                value = this.state.inputs.answers.length;
              }
              this.onChange({ target: { name, value } }); 
            }}
          />
          <div style={{ marginBottom: 20 }}>
            <InlineInput
              label="Image Columns"
              select={true}
              options={[1,2,3,4,5,6,7,8,9]}
              tooltip={<Tooltip>Determine how many columns the images will render in (default is 2).</Tooltip>}
              name="numColumns"
              value={this.state.inputs.numColumns || 2}
              prefix="Have exactly"
              suffix="column(s) of images."
              idx={0}
              onChange={(name, value) => {
                this.onChange({ target: { name, value } }); 
              }}
            />
          </div>
          <div>
            <label>Show Answer Title</label>
            <div className="inline-toggle">
              <Toggle
                active={this.state.inputs.showAnswerTitle}
                onChange={(value) => {
                  this.onChange({ target: { name: 'showAnswerTitle', value: value } }); 
                }}
              />
              <div className="subtle">Print the title for the answer under each image.</div>
            </div>
          </div>
        </div>
        );
      }
      if (type === 'rank') {
        answerRulesInput = (
          <div style={{ marginTop: 20 }}><InlineInput
            label="Answer Rules"
            tooltip={<Tooltip>Example: Enter 2 if you want to require at least 2 responses before submitting.</Tooltip>}
            name="responseLimit"
            value={this.state.inputs.responseLimit}
            prefix={<span>Require <select onChange={this.onChange.bind(this)} name="responseLimitType" value={this.state.inputs.responseLimitType}><option value="all">every</option><option value="at-least">at least</option><option value="exactly">exactly</option><option value="at-most">at most</option></select></span>}
            suffix="answer to be ranked."
            idx={0}
            hideInput={this.state.inputs.responseLimitType === 'all' || this.state.inputs.responseLimitType === undefined}
            onChange={(name, value) => {
              if (value > this.state.inputs.answers.length) {
                value = this.state.inputs.answers.length;
              }
              this.onChange({ target: { name, value } }); 
            }}
          /></div>
        );
      }
      answersInput = (
      <div>
        <div className="section-label"><i className="fas fa-check-to-slot" />Answer Options<Tooltip>Adjust Set value of of different answer you want presented to your survey participants.</Tooltip></div>
        <div className="card has-dynamic-input">
          <AnswersInput
            onChange={(e) => {
              const value = e.target.value;
              const responseLimit = this.state.inputs.responseLimit;

              if (value.length < this.state.inputs.responseLimit) {
                this.onChange({ target: { name: 'responseLimit', value: value.length }});
              }

              this.onChange(e);
            }}
            onValidate={this.onValidate.bind(this)}
            answers={_.cloneDeep(this.state.inputs.answers)}
            slides={slides}
            slideId={this.props.slideId}
            name="answers"
            key={type}
            showRules={false}
            showDynamicAnswer={type === 'rank' ? false : true}
            ref={this.setRef.bind(this)}
          />
          { answerRulesInput }
        </div>
      </div>);
    }

    if (type === 'binary') {
      answersInput = (
      <div>
       <div className="section-label"><i className="fas fa-check-to-slot" />Answer Options<Tooltip>Set the value of each different answer you want presented to your survey participants.</Tooltip></div>
        <BinaryAnswersInput
          onChange={this.onChange.bind(this)}
          onValidate={this.onValidate.bind(this)}
          answers={_.cloneDeep(this.state.inputs.answers)}
          name="answers"
          className="card"
          slides={slides}
          slideId={this.props.slideId}
          ref={this.setRef.bind(this)}
        />
      </div>);
    }

    let labelsInput = null;

    if (type === 'range' || type === 'satisfaction' || type === 'star-rating' || type === 'slider') {
      let rangeLabelInput = null;
      // if (this.state.showRangeLabels) {
      if (true) {
        rangeLabelInput = (<div>
          <div className="input split" style={{ marginTop: 10 }}>
            <NameInput
              onChange={this.onChange.bind(this)}
              onValidate={this.onValidate.bind(this)}
              label="Left Label"
              type="text"
              placeholder="Placeholder for the input area"
              name="leftLabel"
              value={this.state.inputs.leftLabel}
              ref={this.setRef.bind(this)}
              optional={true}
              // maxlength={40}
            />
            <NameInput
              onChange={this.onChange.bind(this)}
              onValidate={this.onValidate.bind(this)}
              label="Right Label"
              type="text"
              placeholder="Placeholder for the input area"
              name="rightLabel"
              value={this.state.inputs.rightLabel}
              ref={this.setRef.bind(this)}
              optional={true}
              // maxlength={40}
            />
          </div>
        </div>)
      }

      labelsInput = (<div>
       <div className="section-label"><i className="fas fa-tags" />Label Options</div>
        <div className="range-input">
          {/*
          <div className="input">
            <label>Show Labels</label>
            <div className="inline-toggle">
              <Toggle
                active={this.state.showRangeLabels}
                onChange={(value) => {
                  this.setState({ showRangeLabels: value });
                  this.onChange({ target: { name: 'leftLabel', value: '' } });
                  this.onChange({ target: { name: 'rightLabel', value: '' } });
                }}
              />
              <div className="subtle">Show labels on each side of the range.</div>
            </div>
          </div>
          */}
          { rangeLabelInput }
        </div>
      </div>
      );
    }

    let rewardInput = null;
    if (type === 'reward') {
      let rewardCodeForm = <NameInput
          onChange={this.onChange.bind(this)}
          onValidate={this.onValidate.bind(this)}
          label="Reward Code"
          type="text"
          placeholder="Reward Code"
          name="rewardCode"
          value={this.state.inputs.rewardCode}
          ref={this.setRef.bind(this)}
          optional={true}
          // maxlength={40}
      />

      if (this.props.account.shop) {
        let rewardCodeFormContent = <NameInput
              onChange={this.onChange.bind(this)}
              onValidate={this.onValidate.bind(this)}
              label="Reward Code"
              type="text"
              placeholder="Reward Code"
              name="rewardCode"
              value={this.state.inputs.rewardCode}
              ref={this.setRef.bind(this)}
              optional={true}
              // maxlength={40}
            />
        if (this.state.inputs.rewardCodeType === 'dynamic') {
          rewardCodeFormContent = <div className="has-dynamic-input" style={{ marginBottom: 30 }}>
            <InlineInput
              label="Discount Rules"
              tooltip={<Tooltip>Enter the rules you would like this dynamic discount code to follow.</Tooltip>}
              name="rewardDiscountValue"
              value={this.state.inputs.rewardDiscountValue}
              prefix={<span>Take <select onChange={this.onChange.bind(this)} name="rewardDiscountType" value={this.state.inputs.rewardDiscountType}><option value="percent">%</option><option value="dollar">{this.props.account.currency || '$'}</option></select></span>}
              suffix="off of the customer's next order."
              idx={0}
              onChange={(name, value) => {
                console.log(name);
                console.log(value);
                this.onChange({ target: { name, value } }); 
              }}
            />
            <div>
              <label>Discount Expiration</label>
              <div className="select-wrapper full-width">
                <select
                  name="rewardDiscountExpiration"
                  value={this.state.inputs.rewardDiscountExpiration}
                  onChange={(e) => {
                    const name = e.target.name;
                    const value = e.target.value;
                    console.log(name);
                    console.log(value);
                    this.onChange({ target: { name, value } })
                  }}
                >
                  <option value="">No Expiration</option>
                  <option value="15m">15 Minutes</option>
                  <option value="30m">30 Minutes</option>
                  <option value="1h">1 Hour</option>
                  <option value="6h">6 Hours</option>
                  <option value="12h">12 Hours</option>
                  <option value="1d">1 Day</option>
                  <option value="2d">2 Days</option>
                  <option value="3d">3 Days</option>
                  <option value="7d">7 Days</option>
                  <option value="14d">14 Days</option>
                  <option value="30d">30 Days</option>
                </select>
              </div>
            </div>
          </div>
        }

        rewardCodeForm = <div>
          <div>
            <div style={{ marginBottom: 15 }}>
              <label>Reward Code Type<Tooltip>Static will use a pre-defined discount code. Dynamic will automatically generate a one-time code per user.</Tooltip></label>
              <Switch
                options={[
                  { label: 'Static', value: 'static' },
                  { label: 'Dynamic', value: 'dynamic' },
                ]}
                value={this.state.inputs.rewardCodeType}
                onChange={(value) => {
                  this.onChange({ target: { name: 'rewardCodeType', value: value } }); 
                }}
              />
            </div>

            { this.state.inputs.rewardCodeType === 'static' ? <div className="info-block">
              <i className="fas fa-info-circle" />
              <p>You need to create codes in the discount section before you use them within Zigpoll. <a href="https://help.shopify.com/en/manual/discounts/discount-types" target="_blank">Read the Shopify documentation<i class="fa-solid fa-arrow-up-right-from-square"></i></a></p>
            </div> : <div className="info-block">
              <i className="fas fa-info-circle" />
              <p>This will automatically generate a one-time code per user based on the settings below. To use a pre-defined global discount code please select "Static" above.</p>
            </div> }

            { rewardCodeFormContent }

          </div>

        </div>
      }

      rewardInput = (<div className="input" style={{ marginTop: 10 }}>
        { rewardCodeForm }
        <WysiwygInput
          key={`reward-banner-${this.props.slide._id}`}
          onChange={this.onChange.bind(this)}
          label=<em>Reward Banner<Tooltip>Optionally add a banner that will appear on all slides of this survey. This can be used to ensure customers know what they can expect before completing the survey.</Tooltip></em>
          placeholder="Get 10% off your next order by completing this survey"
          name="rewardBanner"
          value={this.state.inputs.rewardBanner}
          optional={true}
          // defaultTextAlignment="center"
          // maxlength={40}
        />
      </div>);
    }

    let legalConsentInput = null;
    if (type === 'legal-consent') {
      legalConsentInput = (<div className="input" style={{ marginTop: 10 }}>
        <NameInput
          onChange={(e) => {
            const val = e.target.value;
            const answers = _.cloneDeep(this.state.inputs.answers);
            answers[0].label = val;
            this.onChange({ target: { name: 'answers', value: answers } });
          }}
          onValidate={this.onValidate.bind(this)}
          label="Checkbox Label"
          type="text"
          placeholder="Checkbox Label"
          name="consentLabel"
          value={this.state.inputs.answers[0].label}
          ref={this.setRef.bind(this)}
          optional={false}
        />
      </div>);
    }

    if (type === 'range') {
      const min = parseInt(this.state.inputs.answers[0].handle);
      const max = parseInt(this.state.inputs.answers[this.state.inputs.answers.length-1].handle);

      answersInput = (
        <div>
         <div className="section-label"><i className="fas fa-check-to-slot" />Answer Options<Tooltip>Set the value of each different answer you want to present to your survey participants.</Tooltip></div>
          <RangeInput
            onChange={this.onChange.bind(this)}
            onValidate={this.onValidate.bind(this)}
            max={max}
            min={min}
            name="answers"
            slides={slides}
            answers={_.cloneDeep(this.state.inputs.answers)}
          />
          { labelsInput }
        </div>
      )
    }

    if (type === 'satisfaction') {
      let nextSlideInput = (
        <div className="card" style={{ marginTop: 10, marginBottom: 10 }}>
          <label>Custom Logic</label>
          <SatisfactionNextSlideInput
            onChange={this.onChange.bind(this)}
            name="answers"
            slides={slides}
            answers={_.cloneDeep(this.state.inputs.answers)}
            value={true}
          />
        </div>
      );

      if (slides.length === 0) { nextSlideInput = null; }

      answersInput = (
        <div>
{/*          <div className="section-label"><i className="fas fa-check-to-slot" />Answer Options<Tooltip>Set the value of each different answer you want presented to your survey participants.</Tooltip></div>
          { nextSlideInput }*/}
          <div>{ labelsInput }</div>
        </div>
      );
    }

    if (type === 'star-rating') {
      let nextSlideInput = (
        <div className="card" style={{ marginTop: 10, marginBottom: 10 }}>
          <label>Custom Logic</label>
          <StarRatingNextSlideInput
            onChange={this.onChange.bind(this)}
            name="answers"
            slides={slides}
            answers={_.cloneDeep(this.state.inputs.answers)}
            value={true}
          />
        </div>
      );

      if (slides.length === 0) { nextSlideInput = null; }

      answersInput = (
        <div>
{/*           <div className="section-label"><i className="fas fa-check-to-slot" />Answer Options</div>
          { nextSlideInput }*/}
          <div>{ labelsInput }</div>
        </div>
      );
    }

    if (type === 'file-upload') {
      answersInput = (
        <div>
         <div className="section-label"><i className="fas fa-file-import" />Upload Options<Tooltip>Adjust the rules you want to govern file uploads.</Tooltip></div>
           <div className="card">
            <div className="inline-toggle">
              <label>One file limit</label>
              <Toggle
                active={this.state.inputs.singleFileOnly}
                onChange={(value) => {
                  this.onChange({ target: { name: 'singleFileOnly', value: value } }); 
                }}
              />
              <div className="subtle">Enable to limit uploads to a single file only. Default is multiple files.</div>
            </div>
           </div>
        </div>
      )
    }

    if (type === 'action') {
      answersInput = (<div>
       <div className="section-label"><i className="fas fa-arrow-pointer" />Action Options</div>
        <ActionInputs
          onChange={this.onChange.bind(this)}
          onValidate={this.onValidate.bind(this)}
          actions={this.state.inputs.actions}
          slides={slides}
          slideId={this.props.slideId}
          name="actions"
          key={type}
          ref={this.setRef.bind(this)}
          backgroundColor={this.props.displaySettings.backgroundColor}
        />
      </div>)
    }

    if (type === 'form') {
      answersInput = (<div>
       <div className="section-label"><i className="fas fa-list-ol" />Form Inputs</div>
        <FormInputs
          onChange={this.onChange.bind(this)}
          name="formInputs"
          onValidate={this.onValidate.bind(this)}
          formInputs={this.state.inputs.formInputs}
          setRef={this.setRef.bind(this)}
          removeRef={this.removeRef.bind(this)}
        />
      </div>) 
    }

    let dateRangeInput = null;
    if (type === 'date') {
      let dropdownsInput = null;
      if (this.state.inputs.format === 'dropdowns') {
        dropdownsInput = <div>
          <hr className="dashed" />
          <div style={{ marginTop: 20 }}>
            <div className="input" style={{ marginBottom: 30 }}>
              <label>Display Options<Tooltip zIndex={5}>You can show or hide certain date values if you like. Useful if you only want to mark anniversaries or recurring events. The underlying date will be formated as a proper timestamp so it can be used with third party tools like Klaviyo.</Tooltip></label>
              <div className="inline-toggle" style={{ marginTop: 5 }}>
                <Toggle
                  active={this.state.inputs.hideYear}
                  onChange={(value) => {
                    this.onChange({ target: { name: 'hideYear', value } }); 
                  }}
                />
                <div className="subtle">Hide year option</div>
              </div>
              <div className="inline-toggle" style={{ marginTop: 5 }}>
                <Toggle
                  active={this.state.inputs.hideMonth}
                  onChange={(value) => {
                    this.onChange({ target: { name: 'hideMonth', value } }); 
                  }}
                />
                <div className="subtle">Hide month option</div>
              </div>
              <div className="inline-toggle" style={{ marginTop: 5 }}>
                <Toggle
                  active={this.state.inputs.hideDay}
                  onChange={(value) => {
                    this.onChange({ target: { name: 'hideDay', value } }); 
                  }}
                />
                <div className="subtle">Hide day option</div>
              </div>
            </div>
            <div className="input" style={{ marginBottom: 30 }}>
              <label>Date Format</label>
              <div>
                <Switch
                  options={[
                    { label: 'MM/DD/YYYY', value: 'mm-dd-yyyy' },
                    { label: 'DD/MM/YYYY', value: 'dd-mm-yyyy' },
                  ]}
                  value={this.state.inputs.dateFormat || 'mm-dd-yyyy'}
                  onChange={(value) => {
                    this.onChange({ target: { name: 'dateFormat', value: value } }); 
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      }
      dateRangeInput = (
      <div style={{ zIndex: 5, position: 'relative' }}>
        <div className="section-label"><i className="fas fa-calendar" />Date Range</div>
        <div className="range-input">
          <div className="input" style={{ marginBottom: 30 }}>
            <label>Input Format<Tooltip>You can decide between a standard date picker or multiple dropdowns to select a date. There are different settings available for each type.</Tooltip></label>
            <div>
              <Switch
                options={[
                  { label: 'Date Picker', value: 'datepicker'},
                  { label: 'Dropdowns', value: 'dropdowns'},
                ]}
                value={this.state.inputs.format || 'datepicker'}
                onChange={(value) => {
                  this.onChange({ target: { name: 'format', value: value } }); 
                }}
              />
            </div>
          </div>
          <div className="input split" style={{ marginTop: 10, zIndex: 5 }}>
            <div>
              <label>Minimum Date<span>(Optional)</span></label>
              <DateInput
                type="date"
                value={this.state.inputs.minDate}
                onChange={(value) => { 
                  this.onChange({ target: { name: 'minDate', value: value } }); 
                }}
              />
            </div>
            <div>
              <label>Maximum Date<span>(Optional)</span></label>
              <DateInput
                type="date"
                value={this.state.inputs.maxDate === 'today' ? undefined : this.state.inputs.maxDate }
                onChange={(value) => { 
                  this.onChange({ target: { name: 'maxDate', value: value } }); 
                }}
              />
              <div className="inline-toggle" style={{ marginTop: 5 }}>
                <Toggle
                  active={this.state.inputs.maxDate === 'today'}
                  onChange={(value) => {
                    if (value) {
                      this.onChange({ target: { name: 'maxDate', value: 'today' } }); 
                    } else {
                      this.onChange({ target: { name: 'maxDate', value: null } });                       
                    }
                  }}
                />
                <div className="subtle">Use current day as max.</div>
              </div>
            </div>
          </div>
          { dropdownsInput }
        </div>
      </div>
      );
    }

    if (type === 'slider') {
      const min = this.state.inputs.answers[0] ? parseInt(this.state.inputs.answers[0].handle) : undefined;
      const max = this.state.inputs.answers[this.state.inputs.answers.length-1] ? parseInt(this.state.inputs.answers[this.state.inputs.answers.length-1].handle) : undefined;
      const firstValue = this.state.inputs.answers[1] ? parseInt(this.state.inputs.answers[1].handle) : undefined;
      let step;
      if (firstValue !== undefined && min !== undefined) {
        step = firstValue - min;
      }

      answersInput = (
        <div>
         <div className="section-label"><i className="fas fa-check-to-slot" />Answer Options<Tooltip>Set the value of each different answer you want to present to your survey participants.</Tooltip></div>
          <SliderInput
            onChange={this.onChange.bind(this)}
            onValidate={this.onValidate.bind(this)}
            max={max}
            min={min}
            step={step}
            name="answers"
            slides={slides}
            answers={_.cloneDeep(this.state.inputs.answers)}
          />
          { labelsInput }
        </div>
      )
    }

    if (type === 'image-choice') {
      answersInput = (
        <div>
         <div className="section-label"><i className="fas fa-check-to-slot" />Answer Options<Tooltip>Set the value of each different answer you want to present to your survey participants.</Tooltip></div>
          <div className="card has-dynamic-input">
            <ImageChoiceAnswersInput
              onChange={this.onChange.bind(this)}
              onValidate={this.onValidate.bind(this)}
              answers={_.cloneDeep(this.state.inputs.answers)}
              slides={slides}
              slideId={this.props.slideId}
              name="answers"
              key={type}
              showRules={type === 'question' || type === 'dropdown' ? true : false}
              showDynamicAnswer={true}
              ref={this.setRef.bind(this)}
            />
            <div style={{ marginTop: 20 }}>{ answerRulesInput }</div>
          </div>
        </div>
      )
    }

    let placeholderInput = null;
    if (this.state.inputs.type === 'short-answer' || this.state.inputs.type === 'long-answer' || this.state.inputs.type === 'email-capture' || this.state.inputs.type === 'dropdown') {
      placeholderInput = (
        <NameInput
          onChange={this.onChange.bind(this)}
          onValidate={this.onValidate.bind(this)}
          label="Placeholder Text"
          type="text"
          placeholder={`Placeholder for the ${this.state.inputs.type === 'dropdown' ? 'dropdown' : 'input area'}`}
          name="placeholder"
          value={this.state.inputs.placeholder}
          ref={this.setRef.bind(this)}
          optional={true}
          // maxlength={40}
        />
      );
    }

    if (this.state.inputs.type === 'file-upload') {
      placeholderInput = (
        <NameInput
          onChange={this.onChange.bind(this)}
          onValidate={this.onValidate.bind(this)}
          label="Placeholder Text"
          type="text"
          placeholder={`Placeholder content for the uploader`}
          name="placeholder"
          value={this.state.inputs.placeholder}
          ref={this.setRef.bind(this)}
          optional={true}
          // maxlength={40}
        />
      );      
    }

    let settings = null;
    if (this.state.inputs.type === 'question' || this.state.inputs.type === 'binary' || this.state.inputs.type === 'checkbox') {
      settings = (
          <div style={{ marginTop: 20 }}>
            <label>Show Results</label>
            <div className="inline-toggle">
              <Toggle
                active={this.state.inputs.showResults}
                onChange={(value) => {
                  this.onChange({ target: { name: 'showResults', value: value } }); 
                }}
              />
              <div className="subtle">Show results to user after submission.</div>
            </div>
          </div>
      );
    }

    if (this.state.inputs.type === 'short-answer' || this.state.inputs.type === 'long-answer') {
      let autoApproveResponses = null;
      let sortResponses = null;
      let showResponses = null;
      if (this.state.showResponsesInput) {
        autoApproveResponses = (
          <div style={{ marginTop: 20, position: 'relative', zIndex: 1 }}>
            <label>Auto Approve<Tooltip>If selected, any response will be automatically displayed on this slide publically. If you would like to manually approve each response, keep this inactive.</Tooltip></label>
            <div className="inline-toggle">
              <Toggle
                active={this.state.inputs.autoApproveResponses}
                onChange={(value) => {
                  this.onChange({ target: { name: 'autoApproveResponses', value: value } }); 
                }}
              />
              <div className="subtle">Automatically approve responses for display.</div>
            </div>
          </div>
        );

        sortResponses = (
          <div style={{ marginTop: 20, position: 'relative', zIndex: 1 }}>
            <label>Sort Responses<Tooltip>If selected, any response will be automatically displayed on this slide publically. If you would like to manually approve each response, keep this inactive.</Tooltip></label>
            <div className="inline-toggle">
              <Switch
                options={[
                  { label: 'By Votes', value: 'votes'},
                  { label: 'By Most Recent', value: 'recent'},
                  { label: 'Manually', value: 'manual'}
                ]}
                value={this.state.inputs.sortBy || 'votes'}
                onChange={(value) => {
                  this.onChange({ target: { name: 'sortBy', value: value } }); 
                }}
              />
            </div>
          </div>
        );

        showResponses = (
          <div style={{ marginTop: 20, position: 'relative', zIndex: 1 }}>
            <label>Reveal Responses<Tooltip>Control when a user sees the approved responses to this slide.</Tooltip></label>
            <div className="inline-toggle">
              <Switch
                options={[
                  { label: 'After User Submits', value: 'afterSubmission'},
                  { label: 'Immediately', value: 'beforeSubmission'},
                ]}
                value={this.state.inputs.showResponses}
                onChange={(value) => {
                  this.onChange({ target: { name: 'showResponses', value: value } }); 
                }}
              />
            </div>
          </div>
        );
      }

      settings = (
        <span>
          <div style={{ marginTop: 20, position: 'relative', zIndex: 1 }}>
            <label>Show Responses</label>
            <div className="inline-toggle">
              <Toggle
                active={this.state.showResponsesInput}
                onChange={(value) => {
                  if (value) {
                    this.onChange({ target: { name: 'showResponses', value: 'afterSubmission' } }); 
                  } else {
                    this.onChange({ target: { name: 'showResponses', value: false } });
                  }
                  this.setState({ showResponsesInput: value });
                }}
              />
              <div className="subtle">Show responses to the user.</div>
            </div>
          </div>

          { showResponses }
          { autoApproveResponses }
          { sortResponses }
        </span>
      );
    }

    let optional = null;
    if (this.state.inputs.type !== 'copy' && this.state.inputs.type !== 'reward') {
      optional = (
        <div style={{ marginTop: 20 }}>
          <label>Optional</label>
          <div className="inline-toggle">
            <Toggle
              active={this.state.inputs.optional}
              onChange={(value) => {
                this.onChange({ target: { name: 'optional', value: value } }); 
              }}
            />
            <div className="subtle">Allow participants to skip past the slide.</div>
          </div>
        </div>
      )
    }

    let handleInput = null;
    if (this.state.showHandleInput) {
      handleInput = (
      <div style={{ marginTop: 10 }}>
        <NameInput
          onChange={this.onChange.bind(this)}
          onValidate={this.onValidate.bind(this)}
          label="Handle"
          type="text"
          placeholder="Handle for the slide"
          name="handle"
          value={this.state.inputs.handle}
          errorMessage="Please a handle for the slide."
          ref={this.setRef.bind(this)}
          optional={false}
          // maxlength={75}
        />
      </div>)
    }

    let hidden = null;
    let customMetafieldKey = null;
    let handle = null;
    let nextSlideInput = null;
    let randomizeAnswers = null;
    let reverseOrder = null;
    let disableSentimentAnalysis = null;
    let hideConfetti = null;
    let showConfetti = null;
    let charCountInput = null;
    let formatInput = null;

    handle = (
      <div style={{ marginTop: 20, position: 'relative', zIndex: 1 }}>
        <label>Custom Handle<Tooltip>By default the handle gets set to the slide's title. If you use the same title across multiple slides, then you will want to set a handle so you can keep track of each slide more easily within the app.</Tooltip></label>
        <div className="inline-toggle">
          <Toggle
            active={this.state.showHandleInput}
            onChange={(value) => {
              this.setState({ showHandleInput: value });
              this.state.inputs.handle = stripTags(this.state.inputs.title);
            }}
          />
          <div className="subtle">Use a custom handle to identify the slide.</div>

        </div>

        { handleInput }
      </div>
    );

    if (this.state.inputs.type === 'question' || this.state.inputs.type === 'checkbox' || this.state.inputs.type === 'dropdown' || this.state.inputs.type === 'inline-multiple-choice' || this.state.inputs.type === 'rank' || this.state.inputs.type === 'image-choice') {
      randomizeAnswers = (
        <div style={{ marginTop: 20 }}>
          <label>Randomize Answers</label>
          <div className="inline-toggle">
            <Toggle
              active={this.state.inputs.randomizeAnswers}
              onChange={(value) => {
                this.onChange({ target: { name: 'randomizeAnswers', value: value } }); 
              }}
            />
            <div className="subtle">Show answers in random order.</div>
          </div>
        </div>
      );
    }

    if (this.state.showAdvancedOptions) {
      hidden = (
        <div style={{ marginTop: 20, position: 'relative', zIndex: 1 }}>
          <label>Hidden<Tooltip>Sometimes you might want to hide a slide but keep the answers because you might show it again later. If this is the case, use this switch.</Tooltip></label>
          <div className="inline-toggle">
            <Toggle
              active={this.state.inputs.hidden}
              onChange={(value) => {
                this.onChange({ target: { name: 'hidden', value: value } }); 
              }}
            />
            <div className="subtle">Hide the slide within the survey.</div>
          </div>
        </div>
      );

      if (this.props.poll.settings.writeToCustomerMetafields) {
        customMetafieldKey = (<MetafieldKeyInput
          onChange={(value) => {
            this.onChange({ target: { name: 'shopifyMetafieldConfig', value: value } });
          }}
          active={this.state.inputs.shopifyMetafieldConfig}
          namespace={ this.state.inputs.shopifyMetafieldConfig ? this.state.inputs.shopifyMetafieldConfig.namespace : this.props.poll.settings.customerMetafieldNamespace || 'zigpoll' }
          mKey={ this.state.inputs.shopifyMetafieldConfig ? this.state.inputs.shopifyMetafieldConfig.key : this.state.inputs.handle }
        />);
      }

      if (this.state.inputs.type !== 'reward' && this.state.inputs.type !== 'copy') {
        disableSentimentAnalysis = (
          <div style={{ marginTop: 20, position: 'relative', zIndex: 1 }}>
            <label>Sentiment Analysis<Tooltip>Sentiment analysis works well when you have a predefined set of options like 1-5 stars or a satisfaction survey. For open ended questions, you may want to have it disabled to avoid false positive or negatives.</Tooltip></label>
            <div className="inline-toggle">
              <Toggle
                active={!this.state.inputs.disableSentimentAnalysis}
                onChange={(value) => {
                  this.onChange({ target: { name: 'disableSentimentAnalysis', value: !value } }); 
                }}
              />
              <div className="subtle">Enable sentiment analysis for this slide.</div>
            </div>
          </div>
        );
      }

      if (['short-answer', 'long-answer'].indexOf(this.state.inputs.type) !== -1) {
        charCountInput = (<CharacterLimitInput
          onChange={(value) => {
            this.onChange({ target: { name: 'charLimits', value: value } });
          }}
          active={ this.state.inputs.charLimits }
          min={ this.state.inputs.charLimits ? this.state.inputs.charLimits.min : 0}
          max={ this.state.inputs.charLimits ? this.state.inputs.charLimits.max : undefined}
        />);

        formatInput = (<FormatInput
          onChange={(value) => {
            this.onChange({ target: { name: 'format', value: value } });
          }}
          active={ this.state.inputs.format }
          regex={ this.state.inputs.format ? this.state.inputs.format.regex : undefined }
          message={ this.state.inputs.format ? this.state.inputs.format.message : undefined }
        />);
      }

      // if (slides.length) {
      //   nextSlideInput = (
      //     <div style={{ marginTop: 20, position: 'relative', zIndex: 1 }}>
      //       <label>Custom Logic<Tooltip>By default you will go to the next slide after completing this one. If you would like for the survey to behave differently, click "add logic".</Tooltip></label>
      //       <NextSlideInput
      //         slides={slides}
      //         nextSlide={this.state.inputs.nextSlide}
      //         title="If you complete this slide, do the following"
      //         idx={0}
      //         value={'testing'}
      //         hidden={false}
      //         onChange={this.onChange.bind(this)}
      //       />
      //     </div>
      //   )
      // }

      if (this.state.inputs.type === 'satisfaction' || this.state.inputs.type === 'range') {
        reverseOrder = (
          <div style={{ marginTop: 20 }}>
            <label>Reverse Order</label>
            <div className="inline-toggle">
              <Toggle
                active={this.state.inputs.reverseOrder}
                onChange={(value) => {
                  this.onChange({ target: { name: 'reverseOrder', value: value } }); 
                }}
              />
              <div className="subtle">Reverse order the of responses.</div>
            </div>
          </div>
        );
      }
    }

    if (
      this.state.inputs.type === 'reward'
    ) {
      hideConfetti = (
        <div style={{ marginTop: 20 }}>
          <label>Show Confetti</label>
          <div className="inline-toggle">
            <Toggle
              active={!this.state.inputs.hideConfetti}
              onChange={(value) => {
                this.onChange({ target: { name: 'hideConfetti', value: !value } }); 
              }}
            />
            <div className="subtle">Show confetti when slide appears.</div>
          </div>
        </div>
      );
    }

    if (
      this.state.inputs.type === 'copy' ||
      this.state.inputs.type === 'action'
    ) {
      showConfetti = (
        <div style={{ marginTop: 20 }}>
          <label>Show Confetti</label>
          <div className="inline-toggle">
            <Toggle
              active={this.state.inputs.showConfetti}
              onChange={(value) => {
                this.onChange({ target: { name: 'showConfetti', value: value } }); 
              }}
            />
            <div className="subtle">Show confetti when slide appears.</div>
          </div>
        </div>
      );
    }

    let subtitle = null;
    if (this.hasSubtitle) {
      subtitle = (
        <NameInput
          onChange={this.onChange.bind(this)}
          onValidate={this.onValidate.bind(this)}
          label="Subtitle"
          type="text"
          placeholder="Subtitle for the slide"
          name="subtitle"
          value={this.state.inputs.subtitle}
          errorMessage="Please a subtitle for the slide."
          ref={this.setRef.bind(this)}
          optional={true}
          // maxlength={100}
        />
      );
    }

    let ruleOptions = [
      { title: 'is submitted', value: 'submitted' },
    ];

    if (type === 'copy' || type === 'reward') {
      ruleOptions = [
        { title: 'is presented', value: 'submitted' },
      ];
    }

    if (type !== 'copy' && type !== 'reward') {
      ruleOptions.push({ title: '= (is equal to)', value: '=' });
    }
    if (type === 'checkbox' || type === 'inline-multiple-choice') {
      ruleOptions.push({ title: 'includes any', value: 'includes-any' });
      ruleOptions.push({ title: 'includes all', value: 'includes-all' });
      ruleOptions.push({ title: 'does not include any', value: 'excludes-any' });
      ruleOptions.push({ title: 'does not include all', value: 'excludes-all' });
    }
    if (type === 'range') {
      ruleOptions.push({ title: '> (is greater than)', value: '>' });
      ruleOptions.push({ title: '>= (is greater than or equals)', value: '>=' });
      ruleOptions.push({ title: '< (is less than)', value: '<' });
      ruleOptions.push({ title: '<= (is less than or equals)', value: '<=' });

    }
    if (type === 'short-answer' || type === 'long-answer') {
      ruleOptions.push({ title: 'contains', value: 'contains' });
      ruleOptions.push({ title: 'does not contain', value: 'not-contains' });
    }

    return (
      <div>
        <div className="section-label" style={{ marginTop: 0 }}><i className="fas fa-shapes" />Format<Tooltip bottom={true} zIndex={7} >Click to view format options. You can click "preview" to see an example of each one.</Tooltip></div>

        <div className="slide-type" style={{ zIndex: 6 }}>
          <SlideTypeSelect
            type={this.state.inputs.type}
            onPreview={this.props.onPreview}
            cancelPreview={this.props.cancelPreview}
            preview={this.props.preview}
            onChange={(type) => {
              const previewSlide = _.cloneDeep(previewSlides[type]);

              if (needsConfirmation(this.state.inputs.type, type, this.state.inputs.answers)) {
                this.onConfirm = () => {
                  this.onChange({ target: { name: 'type', value: type } });

                  if (needsAnswersReset(this.state.inputs.type, this.prevType)) {
                    this.onChange({ target: { name: 'answers', value: previewSlide.answers || [] } });
                  }

                  this.onChange({ target: { name: 'formInputs', value: previewSlide.formInputs } });
                }
                this.setState({ showConfirm: true });
              } else {
                this.onChange({ target: { name: 'type', value: type } });

                if (needsAnswersReset(this.state.inputs.type, this.prevType)) {
                  let answers = previewSlide.answers || [];
                  answers = [ ...answers ];

                  if (this.state.inputs.type === 'image-choice') {
                    answers.map((answer) => {
                      delete answer.url;
                    });
                  }

                  this.onChange({ target: { name: 'answers', value: answers } });
                }

                this.onChange({ target: { name: 'formInputs', value: previewSlide.formInputs } });
              }
            }}
          />

          <Confirm 
            title="Are you sure?"
            subtitle="You have settings saved, if you change the type they will be reset."
            show={this.state.showConfirm}
            onConfirm={this.onConfirm}
            onCancel={() => {
              this.onConfirm = null;
              this.setState({ showConfirm: false });
            }}
          />
        </div>

        <div className="section-label"><i className="fas fa-quote-left" />Content<Tooltip bottom={true}>Enter the content for the survey slide. Try to match your brand's unique voice if possible.</Tooltip></div>

        <div className="card" style={{ marginBottom: 10 }}>

          <WysiwygInput
            name="title"
            label="Main Copy"
            // maxlength={500}
            optional={false}
            onChange={this.onChange.bind(this)}
            value={this.state.inputs.title}
            key={`title-${this.props.slide._id}`}
          />

          <SingleImageUploader
            image={this.state.inputs.image}
            onChange={(image) => {
              if (image) {
                this.setState({ localImage: image }); 
                this.onChange({ target: { name: 'image', value: image.url } });
              } else {
                this.setState({ localImage: -1 }); 
                this.onChange({ target: { name: 'image', value: undefined } }); 
              }
            }}
            optional={true}
          />

          { subtitle }

          <WysiwygInput
            name="copy"
            label="Fine Print"
            // maxlength={500}
            optional={true}
            onChange={this.onChange.bind(this)}
            value={this.state.inputs.copy}
            key={`copy-${this.props.slide._id}`}
          />

          { rewardInput }
          { legalConsentInput }
          { placeholderInput }
        </div>

        { answersInput }
        { dateRangeInput }

        <SlideLogicInput
          slideId={this.props.slideId}
          slide={this.props.slide}
          slides={this.props.poll.slides}
          account={this.props.account}
          poll={this.props.poll}
          inputs={this.state.inputs}
          value={this.state.inputs.logic}
          ruleOptions={ruleOptions}
          onChange={(values) => {
            values = _.uniq(values);
            const cleanValues = [];
            const valueRequiredActions = ['skip', 'add-customer-tag', 'add-order-tag', 'add-customer-metafield', 'add-order-metafield'];
            this.hasModifiedLogic = false;

            values.forEach(({ actions, rules }) => {
              rules = _.filter(rules, ({ rule, ruleValue }) => {
                if (!_.find(ruleOptions, ({ value }) => rule === value)) {
                  return false;
                } else {
                  return true;
                }
              });

              actions = _.filter(actions, ({ action, actionValue }) => {
                if (valueRequiredActions.indexOf(action) !== -1 && actionValue === null) {
                  this.hasModifiedLogic = true;
                  return false;
                } else {
                  return true;
                }
              });

              if (actions.length === 0 || rules.length === 0) { return }

              cleanValues.push({ actions: _.uniqBy(actions, action => JSON.stringify(action)), rules: _.uniqBy(rules, rule => JSON.stringify(rule)) });
            });

            values = cleanValues;
            /* Reset NextSlide State */
            // const answers = this.resetNextSlideState();
            // this.onChange({ target: { name: 'nextSlide', value: false }})
            this.onChange({ target: { name: 'logic', value: values }});

            /* Doing in reverse so the one at the top takes priority */
            // values.slice().reverse().forEach((value) => {
            //   let { rules, actions } = value;
            //   let priorityAction;
            //   let priorityActionValue;

            //   actions.slice().reverse().forEach((item) => {
            //     let { action, actionValue } = item;
            //     if (action === 'skip') {
            //       priorityAction = action;
            //       priorityActionValue = actionValue;
            //     }
            //     if (action === 'end') {
            //       priorityAction = action;
            //       priorityActionValue = actionValue;
            //     }
            //   });

            //   rules.slice().reverse().forEach((item) => {
            //     let { rule, ruleValue } = item;
            //     let e;

            //     if (rule === 'submitted' && priorityAction === 'end') {
            //       e = { target: { name: 'nextSlide', value: -1 } };
            //     } else if (rule === 'submitted' && priorityAction === 'skip') {
            //       if (priorityActionValue !== 'no-slides-available') {
            //         e = { target: { name: 'nextSlide', value: priorityActionValue } }
            //       }
            //     } else if (rule === '=') {
            //       const idx = _.findIndex(answers, ({ handle }) => handle == ruleValue) || 0;
            //       const answer = answers[idx] || {};

            //       if (priorityAction === 'end') {
            //         answer.nextSlide = -1;
            //       } else if (priorityAction === 'skip') {
            //         answer.nextSlide = priorityActionValue;
            //       }

            //       e = { target: { name: 'answers', value: answers } }
            //     }

            //     /* Will also want to create a logic array for later improvements. This array should be keyed (not based on indexes for future-future improvements) */
            //     if (e) {
            //       this.onChange(e);
            //     }
            //   });
            // });
          }}
        />

        <div className="section-label"><i className="fas fa-gear" /> Settings<Tooltip bottom={true}>Adjust details for your specific survey type such as randomizing answers or showing special effect here.</Tooltip></div>
        <div class="slide-type settings" style={{ marginTop: 10 }}>
          <div style={{ marginTop: 0 }}>
            <label>Show Title</label>
            <div className="inline-toggle">
              <Toggle
                active={this.state.inputs.showTitle}
                onChange={(value) => {
                  this.onChange({ target: { name: 'showTitle', value: value } }); 
                }}
              />
              <div className="subtle">Show the title for the slide.</div>
            </div>
          </div>

          { optional }
          { settings }
          { handle }
          { hideConfetti }
          { showConfetti }
          { randomizeAnswers }
          { reverseOrder }
          { disableSentimentAnalysis }
          { charCountInput }
          { formatInput }
          { hidden }
          { customMetafieldKey }
          { /*terminal*/ }
          { nextSlideInput }

          <div className="show-advanced" onClick={() => this.setState({ showAdvancedOptions: !this.state.showAdvancedOptions})} >{ !this.state.showAdvancedOptions ? 'More Options' : 'Less Options' }</div>

        </div>

        <Confirm 
          title="Are you sure?"
          subtitle="This survey is visible. If you make this change anyone who has filled out this form will have their progress reset."
          show={this.state.showConfirmModal}
          onConfirm={this.submit.bind(this)}
          onCancel={() => {
            this.setState({ showConfirmModal: false });
          }}
        />

      </div>
    );
  }

  renderActions() {
    return (
      <div className="actions">
        <AuthSubmitButton
          title="Update Slide"
          onClick={this.onSubmit.bind(this)}
          disabled={!this.hasChanged()}
        />

        <div className="login">
          <div>Need help understanding slides?</div>
          <a href="https://docs.zigpoll.com/slides" target="_blank" rel="noopener noreferrer">Learn more</a>
        </div>

        <HeaderButtons>
          <button
            onClick={this.onSubmit.bind(this)}
            disabled={!this.hasChanged()}
          >{ this.state.loading || this.props.loading ? 'Updating...' : 'Update Slide' }</button>
          <Link to={`/a/${encode(this.props.accountId)}/p/${encode(this.props.poll._id)}/s/create`} className="create">Add Slide</Link>
          {/*<SlideBankButton />*/}

        </HeaderButtons>
      </div>
    )
  }

  render() {
    return (
      <div
        className={`slide-form ${this.state.valid === false ? 'invalid' : ''} ${this.state.loading || this.props.loading ? 'loading' : ''}`}>
        { this.renderInputs() }
        { this.renderActions() }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.slides.loading
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...SlideActions, ...FlashNotificationActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SlideEditFormComponent);

