import React, { Component } from 'react'
import { ChromePicker } from '../react-color'

class ColorPicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayColorPicker: false
    }
  };

  handleClick() {
    if (this.props.onOpen && !this.state.displayColorPicker) {
      this.props.onOpen();
    }

    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleClose(e) {
    this.setState({ displayColorPicker: false })

    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  handleChange(color) {
    this.props.onChange(color);
  };

  render() {
    const color = this.props.color || '#ff0000';

    return (
      <div
        className={`color-picker ${this.state.displayColorPicker ? 'active' : ''}`}
      >
        <div className="input" onClick={ this.handleClick.bind(this) }>
          <div className="swatch">
            <div style={{ background: color }} />
          </div>
          <div className="hex">{ color }</div>
        </div>
        <div className="picker">
          <div className="overlay" onClick={ this.handleClose.bind(this) } /> 
          <ChromePicker
            color={ color }
            onChange={(color) => { this.setState({ color: color.hex }); }}
            disableAlpha={true}
            onChangeComplete={ this.handleChange.bind(this) }
          />
        </div>
      </div>
    )
  }
}

export default ColorPicker