import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as AccountActions from '../actions/Accounts';
import * as FlashNotificationActions from '../actions/FlashNotifications';

import { decode } from '../utils';

import Confirm from './Confirm';
import SectionHeader from './SectionHeader';
import { AuthSubmitButton } from './Buttons';

import _ from 'lodash';

function validateEmail(email) {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

class AddAdminForm extends Component {
  constructor(props) {
    super(props);
    this.state = { value: '', showForm: undefined }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.admin, this.props.admin)) {
      this.setState({ showForm: undefined });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    if (!this.state.value || !validateEmail(this.state.value)) {
      this.props.flash('Please enter a valid email address');
      return;
    }

    this.props.onSubmit(this.state.value);
  }

  onChange(e) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ value: e.target.value });
  }

  toggleForm(e) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ showForm: !this.state.showForm });
  }

  renderForm() {
    if (!this.state.showForm) { return null; }

    let message = null;
    if (this.state.message) {
      message = <p>{this.state.message}</p>
    }

    return (
      <div style={{ marginTop: 25 }}>
        <div className="card">
          <form
            onSubmit={this.onSubmit.bind(this)}
          >
            <label>Email Address</label>

            <div>
              <input 
                type="email"
                onChange={this.onChange.bind(this)}
                name="email"
                value={this.state.value}
                placeholder="email@domain.com"
              />

              <AuthSubmitButton
                title="Add"
                disabled={this.props.accounts.loading}
              />
            </div>

            { message }
          </form>
        </div>
        <div className="blue-info-link">
          <a target="_blank" href="https://docs.zigpoll.com/tutorials/adding-team-members">Having trouble? Learn more about how to add team members.</a>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="add-admin-form">
        <div className="toggle-state">
          <AuthSubmitButton
            className="add"
            onClick={this.toggleForm.bind(this)}
            title={ this.state.showForm ? 'Cancel' : 'Add Team Member' }
            disabled={this.props.accounts.loading}
          />
        </div>
        { this.renderForm() }
      </div>
    )
  }
}

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = { showConfirm: false }
  }

  render() {
    const { name, email, _id } = this.props;

    let permission = <span className="pill grey">Admin</span>;
    let remove = null;

    if (_id === this.props.owner) {
      permission = <span className="pill">Owner</span>
    } else {
      remove = (
        <div
          className="remove"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            /* confirm */
            this.setState({ showConfirm: true });
          }}
        />
      );
    }

    return (<div>
      { permission }
      <p><span className="inline-label">Name:</span><strong>{name}</strong></p>
      <p><span className="inline-label">Email:</span><strong>{email}</strong></p>
      { remove }
      <Confirm 
        title="Are you sure?"
        subtitle="This will remove the user from the account."
        show={this.state.showConfirm}
        onConfirm={() => {
          this.props.removeAdmin(this.props._id);
        }}
        onCancel={() => {
          this.setState({ showConfirm: false });
        }}
      />
    </div>);
  }
}

class ManageAdmin extends Component {
  componentDidMount() {
    if (typeof this.props.admin[0] === 'string') {
      this.props.fetchAdmin();
    }
  }

  render() {
    if (typeof this.props.admin[0] === 'string') {
      return null;
    }

    return (<div className={`admin-list ${this.props.accounts.loading ? 'loading' : ''}`}>
      <SectionHeader
        title=<span><i className="fas fa-users" />Team Members</span>
        subtitle="Add or remove members of this account."
        className="no-margin"
        tooltip="Only the account owner can view or edit this list. If you want to switch the account owner email support@zigpoll.com."
      />

      <div className="admin object-list">
        { this.props.admin.map((admin) => <Admin key={admin._id} { ...admin } owner={this.props.owner} removeAdmin={this.props.removeAdmin.bind(this)} />)}
        <div className="admin-limit"><span>{ this.props.account.admin.length } / { this.props.account.plan.accountLimit }</span><div className="content"><div>You can increase your team size by upgrading your plan.</div></div></div>
      </div>

      <AddAdminForm
        admin={this.props.admin}
        onSubmit={this.props.addAdmin.bind(this)}
        removeAdmin={this.props.removeAdmin.bind(this)}
        flash={this.props.flash}
        accounts={this.props.accounts}
      />
    </div>);
  }
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const accounts = state.accounts || {};
  const account = accounts[accountId];
  const user = state.user;

  return {
    accountId,
    accounts,
    account,
    user
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...AccountActions, ...FlashNotificationActions }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageAdmin));
