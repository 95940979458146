import React, { Component } from 'react';
// import { bindActionCreators } from 'redux';
// import { connect } from 'react-redux';

import $ from 'jquery';
import _ from 'lodash';

// import * as AccountActions from '../actions/Accounts';
// import * as FlashNotificationActions from '../actions/FlashNotifications';

import HeaderButtons from '../components/HeaderButtons';
import ColorPicker from '../components/ColorPicker';
import Toggle from '../components/Toggle';
import Switch from '../components/Switch';
import PositionInput from '../components/PositionInput';
import AlignmentInput from '../components/AlignmentInput';
import EmailSettingsVisualDisplay from '../components/EmailSettingsVisualDisplay';
import Tooltip from '../components/Tooltip';
import { NameInput, CCRecipientsInput, ReplyToInput } from '../components/SimpleInputs';
import WysiwygInput from './WysiwygInput';
import IconSelector from '../components/IconSelector';
import SectionHeader from '../components/SectionHeader';

import { AuthSubmitButton } from './Buttons';
import { FontSizeSlider } from './Sliders';
import { defaultEmailDisplaySettings } from '../settings';

import SingleImageUploader from './SingleImageUploader';

export default class DisplaySettingsForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...defaultEmailDisplaySettings, ...props.settings }

    if (!this.state.linkType) {
      this.state.linkType = 'button';
    }

    this.setInitialState();

    this.scroll = _.throttle(this.scroll.bind(this), 0, { leading: true, trailing: true });
  }

  // componentDidMount() {
  //   $('.foreground').on('scroll.account', this.scroll);
  //   this.scroll();
  // }

  // componentWillUnmount() {
  //   $('.foreground').off('scroll.account', this.scroll)
  // }

  scroll() {
    let $el = $('.account-display .visual-display').first();
    
    let zoom = $('html').css('zoom') || 1;
    zoom = parseFloat(zoom);

    let t = $('form.account-settings')[0].getBoundingClientRect().y - $('.foreground')[0].getBoundingClientRect().y * zoom;
    let st = 0;

    let b = $('form.account-settings').height();
    let atBottom = ((st - t) + $el.height()) >= b;

    if ( (t) < 10 && !atBottom ) {
      $el.css('transform', `translateY(${-t + 20}px)`);
    } else if (atBottom) {
      $el.css('transform', `translateY(${b - $el.height()}px)`);
    } else {
      $el.css('transform', `translateY(0px)`);
    }  
  }

  setInitialState() {
    const settings = this.props.settings || defaultEmailDisplaySettings;
    this.initialState = { ...this.state, content: _.cloneDeep(settings.content) };

    setTimeout(() => {
      this.forceUpdate();
    }, 1000);
  }

  componentDidUpdate(prevProps, prevState) {
    console.log('component did update');
  }

  onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    this.updateSettings();
  }

  onContentChange(e) {
    const state = this.state;
    state.content[e.target.name] = e.target.value;
    this.setState(state);
  }

  onValidate(e) {
    console.log('on validate');
  }

  setRef() {
    console.log('set ref called');
  }

  updateSettings() {
    const account = {};
    account._id = this.props.account._id;
    account.emailDisplaySettings = { ...this.state };

    const formData = new FormData();

    if (this.state.localLogoImage) {
      formData.append('logoImage', this.state.localLogoImage);
      delete account.emailDisplaySettings.localLogoImage;
    }

    formData.append('account', JSON.stringify(account));

    this.props.updateAccountEmailDisplaySettings(account._id, formData, 'Your changes have been saved.').then(() => this.setInitialState());
  }

  cancel() {
    this.setState({ ...this.initialState });
    this.forceUpdate();
    this.props.flash('Your changes have been cancelled.');
  }

  onLogoImageUpload(image) {
    if (!image) {
      this.setState({ 
        logoImage: undefined,
        localLogoImage: undefined
      });
    } else {
      this.setState({
        localLogoImage: image,
        logoImage: image.url
      });
    }
  }

  render() {
    const hasChanged = JSON.stringify(this.state) !== JSON.stringify(this.initialState);

    let title = (<SectionHeader
      title=<span><i className="fas fa-mail-bulk"/>Email Design Settings</span>
      subtitle="Control the appearance of your email campaigns."
      className="no-margin center"
    />
  );
    if (this.props.hideTitle) {
      title = null;
    }

    let buttonInput = (
      <div>
        <NameInput
          onChange={this.onContentChange.bind(this)}
          onValidate={this.onValidate.bind(this)}
          label="Button Text"
          type="text"
          placeholder="Click to Start"
          name="button"
          value={this.state.content.button}
          ref={this.setRef.bind(this)}
          optional={false}
          maxlength={40}
        />
      </div>
    );
    if (this.state.linkType !== 'button') {
      buttonInput = null;
    }

    return (
      <div className="account-display" style={this.props.style || {}}>
        { title }
        <div className="split">
          <div>
            <form 
              onSubmit={this.onSubmit.bind(this)}
              className={`account-settings ${this.props.loading ? 'loading' : ''}`}
            >

              <div className="card">
                <div className="card-divider" style={{ marginTop: -15 }}>
                  <h6>Color</h6>
                </div>

                <div style={{zIndex: 9, position: 'relative'}}>
                  <label>Background Color</label>
                  <div className="subtle">Used in the background of your email.</div>
                  <ColorPicker
                    color={this.state.backgroundColor}
                    onChange={(color, event) => {
                      this.setState({ backgroundColor: color.hex });
                    }}
                  />
                </div>

                <div style={{zIndex: 8, position: 'relative'}}>
                  <label>Foreground Color</label>
                  <div className="subtle">Used in the foreground of your email.</div>
                  <ColorPicker
                    color={this.state.foregroundColor}
                    onChange={(color, event) => {
                      this.setState({ foregroundColor: color.hex });
                    }}
                  />
                </div>

                <div style={{zIndex: 7, position: 'relative'}}>
                  <label>Button Background Color</label>
                  <div className="subtle">Background color for your buttons.</div>
                  <ColorPicker
                    color={this.state.buttonColor}
                    onChange={(color, event) => {
                      this.setState({ buttonColor: color.hex });
                    }}
                  />
                </div>

                <div style={{zIndex: 6, position: 'relative'}}>
                  <label>Button Font Color</label>
                  <div className="subtle">Font color for your buttons.</div>
                  <ColorPicker
                    color={this.state.buttonFontColor}
                    onChange={(color, event) => {
                      this.setState({ buttonFontColor: color.hex });
                    }}
                  />
                </div>

                <div className="card-divider">
                  <h6>Font</h6>
                </div>

                <div style={{zIndex: 5, position: 'relative'}}>
                  <label>Font Color</label>
                  <div className="subtle">Font color for your email.</div>
                  <ColorPicker
                    color={this.state.fontColor}
                    onChange={(color, event) => {
                      this.setState({ fontColor: color.hex });
                    }}
                  />
                </div>

                <div style={{ marginTop: 15 }}>
                  <label>Font Family</label>
                  <div className="subtle">Choose a font family that matches your site.</div>

                  <div className="inline-toggle">
                    <Switch 
                      options={[
                        { label: 'Sans Serif', value: 'sans'},
                        { label: 'Serif', value: 'serif', className: 'serif' },
                        { label: 'Slab', value: 'slab', className: 'slab' },
                      ]}
                      value={this.state.fontFamily}
                      onChange={(value) => {
                        this.setState({ fontFamily: value });
                      }}
                    />
                  </div>
                </div>

                <div className="card-divider">
                  <h6>Branding</h6>
                </div>

                <div>
                  <label>Logo<Tooltip>Image should be 800x300 pixels It will be resized automatically on upload.</Tooltip> <span>optional</span></label>
                  <div className="subtle">Use an image for your logo.</div>

                  <SingleImageUploader
                    hideLabel={true}
                    key={this.state.logoImage}
                    image={this.state.logoImage}
                    onChange={this.onLogoImageUpload.bind(this)} />
                </div>

                <div>
                  <label>Zigpoll Link<Tooltip>You can only disable branding if you subscribe to a <strong>Standard</strong> plan or better.</Tooltip></label>
                  <div className="inline-toggle">
                    <Toggle 
                      active={this.state.showLink}
                      disabled={this.props.account.plan.forceBranding}
                      onChange={(value) => {
                        this.setState({ showLink: value });
                      }}
                    />
                    <div className="subtle">Display a subtle link to the Zigpoll website.</div>
                  </div>
                </div>

                <div className="card-divider">
                  <h6>Content</h6>
                </div>

                <div style={{ marginBottom: 25 }}>
                  <CCRecipientsInput
                    onChange={(e) => {
                      const val = e.target.value;
                      this.setState({ ccRecipients: val });
                    }}
                    onValidate={() => {
                      console.log('On validate')
                    }}
                    placeholder="email@domain.com"
                    name="CC Recipients"
                    value={ this.state.ccRecipients }
                    toggleCopy="Do not include anyone as CC."
                    errorMessage="Please enter a valid domain name."
                    ref={this.setRef.bind(this)}
                  />
                </div>

                <div style={{ marginBottom: 25 }}>
                  <ReplyToInput
                    onChange={(e) => {
                      const val = e.target.value;
                      this.setState({ replyTo: val });
                    }}
                    onValidate={() => {
                      console.log('On validate')
                    }}
                    placeholder="email@domain.com"
                    name="Reply To"
                    value={ this.state.replyTo }
                    toggleCopy="Do not include anyone as CC."
                    errorMessage="Please enter a valid domain name."
                    ref={this.setRef.bind(this)}
                  />
                </div>


                <div>
                  <NameInput
                    onChange={(e) => this.setState({ sender: e.target.value }) }
                    onValidate={this.onValidate.bind(this)}
                    label="Sender"
                    moreInfo='By default all customer facing emails will be sent as "Zigpoll". If you would like the name to be something else, enter it below.'
                    type="text"
                    placeholder="Zigpoll"
                    name="sender"
                    value={this.state.sender}
                    ref={this.setRef.bind(this)}
                    optional={false}
                    maxlength={100}
                  />
                </div>

                <div>
                  <NameInput
                    onChange={this.onContentChange.bind(this)}
                    onValidate={this.onValidate.bind(this)}
                    label="Subject"
                    type="text"
                    placeholder="Have a minute for a quick survey?"
                    name="subject"
                    value={this.state.content.subject}
                    ref={this.setRef.bind(this)}
                    optional={false}
                    maxlength={150}
                  />
                </div>

                <div>
                  <WysiwygInput
                    name="header"
                    label="Header"
                    hideLinkInput={true}
                    maxlength={500}
                    optional={false}
                    onChange={this.onContentChange.bind(this)}
                    value={this.state.content.header}
                    defaultTextAlignment="center"
                  />
                </div>

                <div>
                  <WysiwygInput
                    name="body"
                    label="Body"
                    hideLinkInput={true}
                    maxlength={500}
                    optional={false}
                    onChange={this.onContentChange.bind(this)}
                    value={this.state.content.body}
                    defaultTextAlignment="center"
                  />
                </div>

                <div style={{ marginTop: 25, marginBottom: 25 }}>
                  <label>Survey Link Type</label>
                  <div className="subtle">Select whether to show the first input or show a button instead.</div>

                  <div className="inline-toggle">
                    <Switch 
                      options={[
                        { label: 'Input', value: 'input'},
                        { label: 'Button', value: 'button' },
                      ]}
                      value={this.state.linkType }
                      onChange={(value) => {
                        this.setState({ linkType: value });
                      }}
                    />
                  </div>
                </div>
                { buttonInput }

                <div>
                  <WysiwygInput
                    name="footer"
                    label="Footer"
                    maxlength={500}
                    optional={false}
                    onChange={this.onContentChange.bind(this)}
                    value={this.state.content.footer}
                    defaultTextAlignment="center"
                  />
                </div>

                <div className={`actions ${hasChanged ? 'active' : ''}`}>
                  <AuthSubmitButton 
                    title="Save Changes"
                    disabled={!hasChanged}
                  />
                  <button 
                    className="cancel"
                    disabled={!hasChanged}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.cancel();
                    }}
                  >Cancel</button>
                </div>
              </div>
            </form>
          </div>

          <EmailSettingsVisualDisplay
            poll={this.props.poll}
            {...this.state}
          />
        </div>

        <HeaderButtons>
          <button 
            disabled={!hasChanged}
            onClick={this.onSubmit.bind(this)}>{ this.props.loading ? 'Saving...' : 'Save Changes' }</button>

          <button 
            className="cancel"
            disabled={!hasChanged}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.cancel();
            }}
          >Cancel</button>
        </HeaderButtons>
      </div>
    );
  }
}
