import React, { Component } from 'react';
import ColumnLayout from '../components/ColumnLayout';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

import ColumnLoading from './ColumnLoading';

import SlideCreateForm from '../components/SlideCreateForm';

import SingleFormPageHeader from '../components/SingleFormPageHeader';

import SlideVisualDisplay from '../components/SlideVisualDisplay';

import * as PollActions from '../actions/Polls';

import { decode, encode, previewSlides, generatePageDisplaySettings } from '../utils';

class SlideForm extends Component {
  constructor(props) {
    super(props);

    let pollType = 'widget';
    if (this.props.poll.settings && this.props.poll.settings.selector) {
      pollType = 'embed';
    }
    if (this.props.poll.settings && this.props.poll.settings.apiOnly) {
      pollType = 'link';
    }
    if (this.props.poll.settings && this.props.poll.settings.shopifyAbandonedCheckout) {
      pollType = 'abandoned-checkout';
    }
    if (this.props.poll.settings && this.props.poll.settings.shopifyAbandonedCheckout) {
      pollType = 'email';
    }
    if (this.props.poll.settings && this.props.poll.settings.shopifyWebhooks) {
      pollType = 'email';
    }
    if (this.props.poll.settings && this.props.poll.settings.sms) {
      pollType = 'sms';
    }
    if (this.props.poll.settings && this.props.poll.settings.pollType === 'api') {
      pollType = 'api';
    }

    this.state = { slide: this.props.slide, pollType };
  }

  onChange(state) {
    const slide = { ...state };
    /* 
      Not crazy about having nested settings, but seems right from a data perspective 
    */
    slide.settings = {
      showTitle: state.showTitle,
      showResults: state.showResults,
      randomizeAsnwers: state.randomizeAnswers,
      reverseOrder: state.reverseOrder
    }

    this.setState({ slide });
  }

  onPreview(type) {
    if (this.state.preview === type) {
      this.setState({ preview: undefined });
    } else {
      this.setState({ preview: type });
    }
  }

  cancelPreview() {
    this.setState({ preview: undefined })
  }

  render() {

    let slides = [this.state.slide];
    if (this.state.preview) {
      const previewSlide = previewSlides[this.state.preview];

      if (previewSlide) {
        slides = [previewSlide];
      }
    }

    let settings = this.props.account.displaySettings;
    if (this.props.poll.displaySettings) {
      settings = this.props.poll.displaySettings;
    }

    let pageDisplaySettings = this.props.account.pageDisplaySettings || generatePageDisplaySettings(this.props);
    if (this.props.poll.pageDisplaySettings) {
      pageDisplaySettings = this.props.poll.pageDisplaySettings;
    }

    return (
      <div className="slide-form-display">
        <div className="split" style={{ paddingTop: 10 }}>
          <div className="slide-form-wrapper">
            <SlideCreateForm
              poll={this.props.poll}
              account={this.props.account}
              displaySettings={settings}
              slide={this.state.slide}
              pollId={this.props.pollId}
              slideId={this.props.slideId}
              onChange={this.onChange.bind(this)}
              onPreview={this.onPreview.bind(this)}
              cancelPreview={this.cancelPreview.bind(this)}
              preview={this.state.preview}
            />
          </div>
          <SlideVisualDisplay
            singleSlidePage={true}
            type={this.state.type}
            createPage={true}
            slides={slides}
            preview={this.state.preview}
            pageDisplaySettings={pageDisplaySettings}
            pollType={this.state.pollType}
            accountId={this.props.account._id}
            {...settings}
          />
        </div>
      </div>
    );
  }
}


class CreateSlide extends Component {
  constructor(props) {
    super(props);

    if (!this.props.account || !this.props.poll) {
      this.props.fetchAccountAndPoll();
    }
  }

  changeType(type) {
    this.setState({ type });
  }

  render() {
    if (!this.props.account || !this.props.poll) { 
      return <ColumnLoading />;
    }

    return (
      <ColumnLayout
        title="Create Slide"
        className="slide"
      >
        <div style={{ minWidth: 1100 }}>
          <div className="top-nav slide-nav">
            <div className="wrapper" style={{ maxWidth: 1440 }}>
              <Link to={`/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}?tab=slides`}><i className="fas fa-arrow-left-long" />Back To Slides</Link>
            </div>
          </div>

          <SlideForm
            slide={previewSlides['copy']}
            pollId={this.props.pollId}
            account={this.props.account}
            poll={this.props.poll}
          />
        </div>
      </ColumnLayout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const accounts = state.accounts || [];
  const account = accounts[accountId];

  const pollId = decode(ownProps.match.params.pollId);
  const polls = state.polls || [];
  const poll = polls[pollId];

  return {
    accountId,
    account,
    pollId,
    poll
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(PollActions, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateSlide));
