import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { withRouter } from 'react-router-dom';
// import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { encode, decode, getAccountId, renderText, getShareDomain, downloadURI, loadContactUsSurvey } from '../utils';

import _ from 'lodash';

import VisibilityToggle from './VisibilityToggle';
import QRCodeModal from './QRCodeModal';

function generateSettingsUrl ({ accountId, pollId, slideId }) {
  let url = `/settings`;

  if (accountId) {
    url = url + `/a/${encode(accountId)}`;
  }
  if (pollId) {
    url = url + `/p/${encode(pollId)}`;
  }
  if (slideId) {
    url = url + `/s/${encode(slideId)}`;
  }

  return url;
}

class HelpWidget extends Component {
  render() {
    const { user } = this.props;

    return <div id="help-widget">
      <span></span>

      <div>
        <span>
          <strong>Need Help?</strong>

          <a target="_blank" rel="noopener noreferrer" href="https://www.zigpoll.com/contact" onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            loadContactUsSurvey(user);
          }}><i className="fas fa-comment" />Contact Us</a>
          <a target="_blank" rel="noopener noreferrer" href="https://docs.zigpoll.com"><i className="fas fa-book" />User Manual</a>
          <a target="_blank" rel="noopener noreferrer" href="https://calendly.com/jason-zigpoll/zigpoll-chat"><i className="fas fa-video" />Book a Demo</a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.zigpoll.com/resources"><i className="far fa-folder-open" />Resource Index</a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.zigpoll.com/case-studies"><i className="fas fa-briefcase" />Case Studies</a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/jason-zigelbaum"><i className="fab fa-linkedin" />Connect</a>
        </span>
        {/*
        <a target="_blank"
          rel="noopener noreferrer"
          href="https://www.zigpoll.com/contact"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            window.drift.identify(user._id, {
              email: user.email,
              name: user.name,
              shop: user.shop
            })
            window.drift.api.openChat();
          }}
        ><i className="fas fa-comments" />Live Chat</a>
        */}
      </div>
    </div>
  }
}

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderRight() {
    const { user } = this.props;

    if (user.loading) {
      return null;
    }

    if (user._id) {
      return (<span>
        <HelpWidget 
          user={user}
        />
        <Link to={generateSettingsUrl(this.props)} className="settings"><span>{user.name.slice(0,1)}</span></Link>
      </span>);
    }

    if (this.props.location.pathname === '/log-in') {
      return (<Link to="/sign-up" className="not-authenticated">Sign Up</Link>)
    }

    return (<Link to="/log-in" className="not-authenticated">Log In</Link>)
  }

  buildLink() {
    let link = `${getShareDomain(this.props.account)}${encode(this.props.accountId)}/${encode(this.props.pollId)}`;

    const { variables } = this.props;
    if (variables.length) {
      let params = variables.map(({ label, value }) => {
        if (label && value) {
          return `${label}=${value}`;
        }
      });
      params = _.compact(params);
      link = `${link}?${params.join('&')}`;
    }

    return link;
  }

  renderPublicLink() {
    if (!this.props.pollId) { return null; }
    if (!this.props.poll) { return null; }
    if (!this.props.poll.isVisible) {
      if (!this.props.poll.slides || !this.props.poll.slides.length) { return null; }

      if (this.props.pageType !== 'slide-edit' && this.props.pageType !== 'poll-edit' && this.props.pageType !== 'poll-dashboard') { return null; }

      return <div id="public-link">
      <VisibilityToggle />
      <a target='_blank' rel="noopener noreferrer" href={`/preview/${this.props.accountId}/${this.props.pollId}`}><i className="fas fa-external-link-alt" /><div className="tooltip">Preview your survey</div></a>
    </div>
    }
    if (this.props.poll.isArchived) { return null; }
    if (this.props.pageType !== 'slide-edit' && this.props.pageType !== 'poll-edit' && this.props.pageType !== 'poll-dashboard') { return null; }

    let link = this.buildLink();

    return (
      <div id="public-link">
        <VisibilityToggle />
        <a target='_blank' rel="noopener noreferrer" href={`/preview/${this.props.accountId}/${this.props.pollId}`}><i className="fas fa-external-link-alt" /><div className="tooltip">Preview your survey</div></a>
        <a href={link} target="_blank"><i className="fas fa-link" /><div className="tooltip">View the public link in a new tab.</div></a>
        <div onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          this.setState({ showQRCodeModal: true });
        }}><i className="fas fa-qrcode" /><div className="tooltip">Get a QR code for this survey.</div></div>
        <QRCodeModal
          isOpen={this.state.showQRCodeModal}
          onClose={() => this.setState({ showQRCodeModal: false })}
          poll={this.props.poll}
          link={link}
        />
      </div>
    );
  }

  render() {
    let rootUrl = '/';

    if (this.props.accountId) {
      rootUrl = `/dashboard/a/${encode(this.props.accountId)}`
    }

    return (<div id="header">
      <div className="wrapper">
        <div className="logo"><div><Link to={rootUrl}>Zigpoll</Link></div></div>

        <div id="buttons" />

        <div className="title">
          { renderText(this.props.title, 40) }
        </div>

        <div id="visibility" />

        { this.renderPublicLink() }

        <div className="right">
          { this.renderRight() }
        </div>
      </div>
    </div>);
  }

};

function mapStateToProps(state, ownProps) {
  let accountId = decode(ownProps.match.params.accountId);

  const pollId = decode(ownProps.match.params.pollId);
  const slideId = decode(ownProps.match.params.slideId);

  if (!accountId && state.user && state.user.accounts) {
    accountId = getAccountId(state.user.accounts[0]);
  }

  const accounts = state.accounts || {};
  const account = accounts[accountId] || {};

  const polls = state.polls || [];
  const poll = polls[pollId];

  const misc = state.misc;
  const queryVars = misc.queryVars || {};
  const variables = queryVars[pollId] || [];

  return {
    accountId,
    account,
    pollId,
    slideId,
    user: state.user,
    poll,
    variables
  }
}

// function mapDispatchToProps(dispatch) {
//   return bindActionCreators(AccountActions, dispatch);
// }

export default withRouter(connect(mapStateToProps)(Header));