import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import _ from 'lodash';

import ColumnLoading from './ColumnLoading';
import ColumnLayout from '../components/ColumnLayout';
import Breadcrumbs from '../components/Breadcrumbs';
import HiddenNotification from '../components/HiddenNotification';

import SlideEditForm from '../components/SlideEditForm';

import * as SlideActions from '../actions/Slides';
import * as UserActions from '../actions/Users';

import SingleFormPageHeader from '../components/SingleFormPageHeader';

import EditSlideActions from '../components/EditSlideActions';
import SlideVisualDisplay from '../components/SlideVisualDisplay';

import Modal from '../components/Modal';
import VisibilityToggle from '../components/VisibilityToggle';

import { encode, decode, previewSlides, generatePageDisplaySettings } from '../utils';

class SlideForm extends Component {
  constructor(props) {
    super(props);

    let pollType = 'widget';
    if (this.props.poll.settings && this.props.poll.settings.selector) {
      pollType = 'embed';
    }
    if (this.props.poll.settings && this.props.poll.settings.apiOnly) {
      pollType = 'link';
    }
    if (this.props.poll.settings && this.props.poll.settings.shopifyAbandonedCheckout) {
      pollType = 'abandoned-checkout';
    }
    if (this.props.poll.settings && this.props.poll.settings.shopifyAbandonedCheckout) {
      pollType = 'email';
    }
    if (this.props.poll.settings && this.props.poll.settings.shopifyWebhooks) {
      pollType = 'email';
    }
    if (this.props.poll.settings && this.props.poll.settings.sms) {
      pollType = 'sms';
    }
    if (this.props.poll.settings && this.props.poll.settings.pollType === 'api') {
      pollType = 'api';
    }

    this.state = { slide: this.props.slide, pollType };
  }

  onChange(state) {
    const slide = { ...state };
    /* 
      Not crazy about having nested settings, but seems right from a data perspective 
    */
    slide.settings = {
      showTitle: state.showTitle,
      showResults: state.showResults,
      randomizeAnswers: state.randomizeAnswers,
      reverseOrder: state.reverseOrder
    }

    this.setState({ slide });
  }

  onPreview(type) {
    if (this.state.preview === type) {
      this.setState({ preview: undefined });
    } else {
      this.setState({ preview: type });
    }
  }

  cancelPreview() {
    this.setState({ preview: undefined })
  }

  render() {
    let slides = [this.state.slide];
    if (this.state.preview) {
      const previewSlide = previewSlides[this.state.preview];

      if (previewSlide) {
        slides = [previewSlide];
      }
    }

    let settings = this.props.account.displaySettings;
    if (this.props.poll.displaySettings) {
      settings = this.props.poll.displaySettings;
    }

    let pageDisplaySettings = this.props.account.pageDisplaySettings || generatePageDisplaySettings(this.props);
    if (this.props.poll.pageDisplaySettings) {
      pageDisplaySettings = this.props.poll.pageDisplaySettings;
    }

    const pollSlides = this.props.poll.slides;
    const idx = _.findIndex(pollSlides, ({_id}) => this.props.slideId === _id );
    const isLast = (idx === (pollSlides.length - 1));

    let rewardBanner;
    pollSlides.forEach((slide, i) => {
      if (slide.rewardBanner && (i !== idx)) {
        rewardBanner = slide.rewardBanner;
      }
    });

    if (isLast) {
      slides[0].buttonType = 'close';
    } else {
      slides[0].buttonType = 'next';      
    }

    return (
      <div className="slide-form-display">
        <div className="split" style={{ paddingTop: 10 }}>
          <div className="slide-form-wrapper">
            <SlideEditForm
              accountId={this.props.account._id}
              poll={this.props.poll}
              account={this.props.account}
              displaySettings={settings}
              slide={this.state.slide}
              slideId={this.props.slideId}
              onChange={this.onChange.bind(this)}
              onPreview={this.onPreview.bind(this)}
              cancelPreview={this.cancelPreview.bind(this)}
              preview={this.state.preview}
            />
          </div>
          <SlideVisualDisplay
            singleSlidePage={true}
            type={this.state.type}
            slides={slides}
            preview={this.state.preview}
            pageDisplaySettings={pageDisplaySettings}
            pollType={this.state.pollType}
            rewardBanner={rewardBanner}
            hideCloseButton={this.props.poll.settings.hideCloseButton}
            accountId={this.props.account._id}
            {...settings}
          />
        </div>
      </div>
    );
  }
}

class Slide extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    if (!this.props.account || !this.props.poll) {
      this.props.fetchAccountPollAndSlide();
    } else if (!this.props.slide) {
      this.props.fetchSlide();
    }

    // this.props.updateUser({ hasCreatedSlide: false, _id: this.props.user._id });
  }

  componentDidUpdate(prevProps) {
    if (this.props.slideId !== prevProps.slideId) {
      this.props.fetchSlide();
    }
  }

  closeModal() {
    console.log('closing modal');
    this.setState({ hideModal: true });
    this.props.updateUser({ hasCreatedSlide: true, _id: this.props.user._id }, true);
  }

  render() {
    if (!this.props.slide || !this.props.account || !this.props.poll) {
      return (
        <ColumnLoading />
      );
    }

    let nextPrev = null;
    if (this.props.poll && this.props.poll.slides.length > 1) {

      const slides = this.props.poll.slides || [];
      const idx = _.findIndex(slides, ({_id}) => this.props.slideId === _id );

      let next = null;
      let prev = null;

      if (idx < slides.length - 1) {
        next = (<Link className="next" to={`/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}/s/${encode(slides[idx+1]._id)}?tab=details`}>Next</Link>);
      } else {
        next = <span className="next">Next</span>
      }

      if (idx !== 0 && slides.length > 1) {
        prev = (<Link className="prev" to={`/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}/s/${encode(slides[idx-1]._id)}?tab=details`}>Prev</Link>);
      } else {
        prev = <span className="prev">Prev</span>
      }

      nextPrev = (<div className="right next-prev">
        { prev }
        { next }
      </div>);
    }

    let notification = null;
    // if (this.props.slide.settings.hidden) {
    //   notification = (<div className="notification">
    //     <p><i className="fas fa-eye-slash" />This slide is currently hidden.</p>
    //   </div>);
    // }

    let isHidden = this.props.slide.settings.hidden;

    const tabs = (<div className={`big-switch ${this.state.type === 'cta' ? 'right' : 'left'}`}>
      <div 
        onClick={() => {
          this.setState({ type: 'content'});
        }}
        className={`${this.state.type === 'cta' ? '' : 'active'}`}>Content & Logic</div>
      <div
        onClick={() => {
          this.setState({ type: 'cta'});
        }}
        className={`${this.state.type === 'cta' ? 'active' : ''}`}>Buttons & Hints</div>
    </div>);

    return (
      <ColumnLayout
        title={`Slide Details`}
        className="slide"
        pageType="slide-edit"
        headerNotification={isHidden && <HiddenNotification showSlide={this.props.showSlide.bind(this)} />}
      >
        <div style={{ minWidth: 1100 }}>
          <div className="top-nav slide-nav">
            <div className="wrapper" style={{ maxWidth: 1440 }}>
              <Link to={`/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}?tab=slides`}><i className="fas fa-arrow-left-long" />Back To Slides</Link>

              {/*{ tabs }*/}

              { nextPrev }
            </div>
          </div>

          <SlideForm
            key={this.props.slideId}
            slide={this.props.slide}
            slideId={this.props.slideId}
            account={this.props.account}
            poll={this.props.poll}
            type={this.state.type}
          />

          <EditSlideActions { ...this.props }/>

          <Modal
            isOpen={this.state.hideModal ? false : (!this.props.user.hasCreatedSlide && (window.prevUrl && window.prevUrl.indexOf('/s/create') !== -1))}
            onRequestClose={this.closeModal.bind(this)}
          >
            <div className="frame" style={{maxWidth: 650}}>
              <div className="close" onClick={this.closeModal.bind(this)} />
              <div className="title">You made your first slide!</div>
              <div className="content">
                <div className="subtitle">Congrats! Now lets keep you moving.<br/>What would you like to do next?</div>
                <div className="actions first-slide">
                  <Link to={`/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}/s/create`} className="positive" onClick={this.closeModal.bind(this)}>Create another slide</Link>
                  <button className="positive" onClick={this.closeModal.bind(this)}>Edit this slide</button>
                  <Link to={`/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}`} className="positive" onClick={this.closeModal.bind(this)}>Go back to your survey</Link>
                  <Link to={`/a/${encode(this.props.accountId)}`} className="positive" onClick={this.closeModal.bind(this)}>Customize your account</Link>

                  <a className="link" href="https://docs.zigpoll.com" target="_blank" rel="noopener noreferrer">Not sure? Read our docs.</a>
                </div>
              </div>
            </div>
          </Modal>
        </div>

      </ColumnLayout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const accounts = state.accounts || [];
  const account = accounts[accountId];

  const pollId = decode(ownProps.match.params.pollId);
  const polls = state.polls || [];
  const poll = polls[pollId];

  const slideId = decode(ownProps.match.params.slideId);

  const slides = state.slides || [];
  const slide = slides[slideId];

  return {
    accountId,
    account,
    pollId,
    poll,
    slideId,
    slides,
    slide,
    user: state.user
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...SlideActions, ...UserActions }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Slide));
