import React, { Component } from 'react';

import tinycolor from 'tinycolor2';

import veryUnsatisfiedImg from '../images/very-unsatisfied.png';
import unsatisfiedImg from '../images/unsatisfied.png';
import neutralImg from '../images/neutral.png';
import satisfiedImg from '../images/satisfied.png';
import verySatisfiedImg from '../images/very-satisfied.png';

const icons = {
  'very-unsatisfied': veryUnsatisfiedImg,
  'unsatisfied': unsatisfiedImg,
  'neutral': neutralImg,
  'satisfied': satisfiedImg,
  'very-satisfied': verySatisfiedImg
};

const fonts = {
  'sans': 'Helvetica',
  'serif': 'Georgia',
  'slab': 'Courier New'
}

function allowInputs(type) {
  var allowedTypes = ['range', 'star-rating', 'satisfaction', 'question', 'checkbox', 'binary', 'rank', 'short-answer', 'long-answer', 'email-capture']
  return (allowedTypes.indexOf(type) !== -1);
}

class Row extends Component {
  render() {
    if (!this.props.content) { return null; }

    return (
      <div className={this.props.className}
        dangerouslySetInnerHTML={{ __html : this.props.content }} />
    );
  }
}

export default class BrowserFrame extends Component {
  render() {
    const { content } = this.props;
    let contentStyle = {};
    let footerStyle = {};

    if (this.props.foregroundColor) {
      contentStyle.backgroundColor = this.props.foregroundColor;
      contentStyle.color = this.props.fontColor;
    }

    if (this.props.fontFamily) {
      contentStyle.fontFamily = fonts[this.props.fontFamily];
    }

    let buttonStyle = {};
    if (this.props.buttonColor) {
      buttonStyle.backgroundColor = this.props.buttonColor;
    }
    if (this.props.buttonFontColor) {
      buttonStyle.color = this.props.buttonFontColor;
    }

    let logo = "https://s3.us-east-2.amazonaws.com/zigpoll/dist/img/logo.png";
    if (this.props.logoImage) {
      logo = this.props.logoImage;
    }

    let footer = <div className="zigpoll-link"><a href="https://www.zigpoll.com" target="_blank" style={ footerStyle }>Powered by <span>Zigpoll</span></a></div>
    if (!this.props.showLink) {
      footer = null;
    }

    const poll = this.props.poll || { slides: [] };
    const firstSlide = poll.slides[0] || {};
    const slideType = firstSlide.type;

    let buttonContent = <div className="button" style={buttonStyle}>{ content.button }</div>
    let labels = null;

    if (firstSlide.leftLabel || firstSlide.rightLabel) {
      labels = <div className="labels">
        { firstSlide.leftLabel && <div className="left-label">{ firstSlide.leftLabel }</div> }
        { firstSlide.rightLabel && <div className="right-label">{ firstSlide.rightLabel }</div> }
      </div>
    }

    if (this.props.linkType === 'input' && allowInputs(slideType)) {
      if (slideType === 'range') {
        buttonContent = <div><div className="range">
          { firstSlide.answers.map((val) => <div style={{ width: (100/firstSlide.answers.length) + '%'}}><div>{ val.title }</div></div>) }
        </div>
        { labels }
        </div>
      }

      if (slideType === 'star-rating') {
        buttonContent = <div><div className="range">
          { firstSlide.answers.map((val) => <div><div><i className="fas fa-star" /></div></div>) }
        </div>
        { labels }
        </div>
      }

      if (slideType === 'satisfaction') {
        buttonContent = <div><div className="range">
          { firstSlide.answers.map((val) => <div><div><img src={icons[val.handle]} /></div></div>) }
        </div>
        { labels }
        </div>
      }

      if (slideType === 'question' || slideType === 'binary') {
        buttonContent = <div className="answers">
          { firstSlide.answers.map((val) => <div><div>{ val.title }</div></div>) }
        </div>
      }

      if (slideType === 'checkbox' || slideType === 'rank') {
        buttonContent = <div className="answers multi-choice">
          { firstSlide.answers.map((val) => <div><div>{ val.title }</div></div>) }
        </div>
      }

      if (slideType === 'short-answer' || slideType === 'long-answer' || slideType === 'email-capture') {
        buttonContent = <div className={`input-box ${slideType}`}>
          <div></div>
        </div>
      }
    }

    return (
      <div className="email-preview-wrapper">
        <div className="email-preview-header">
          <div className="from"><strong>From:</strong> { this.props.sender || 'Zigpoll' }</div>
          <div className="subject"><strong>Subject:</strong> { content.subject }</div>
        </div>
        <div class="email-preview">
          <div className="email-content">
            <div className="top-content" style={{ borderColor: this.props.buttonColor }}>
              <div className="logo"><img src={ logo } /></div>
            </div>
            <div className="content" style={contentStyle}>
            <div style={{ maxWidth: 500, margin: '0px auto' }}>
                <Row className="header" content={ content.header }/>
                <Row className="body" content={ content.body } />

                { buttonContent }

                <Row className="footer" content={ content.footer } />
              </div>
            </div>
            {/*{ footer }*/}
          </div>
        </div>
      </div>
    );
  }
}