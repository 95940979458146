import React, { Component } from 'react';
import Option from './SwitchOption';
import InlineInput from './InlineInput';

export default class Switch extends Component {
  handleClick(e, value) {
    e.preventDefault();
    e.stopPropagation();

    if (this.props.disabled) {
      return true;
    }

    this.props.onChange(value);
  }

  render() {
    return (<div
      className={`switch ${this.props.disabled ? 'disabled' : ''} ${this.props.vertical ? 'vertical' : ''} ${this.props.vertical ? 'vertical' : ''} ${this.props.className ? this.props.className : ''}`}
      active={this.props.active}
    >
      { this.props.options.map(option => {
        let selected;
        if (option.value === this.props.value) {
          selected = true;
        }
        {/* Probably a better solution here... */}
        if (option.inlineInput) {
          return (<div 
            {...option}
            key={option.value}
            className={`${selected ? 'selected' : ''}`}
            onClick={(e) => this.handleClick(e, option.value)}>
            <InlineInput
              name={ option.name }
              value={ option.inputValue }
              prefix={ option.prefix }
              suffix={ option.suffix }
              onChange={(name, value) => this.props.onChange(value)}
            /></div>);
        }
        return <Option
          {...option}
          key={option.value}
          selected={selected}
          onClick={this.handleClick.bind(this)}
        />
      }) }
    </div>)
  }
}