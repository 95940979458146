import React, { Component } from 'react';
// import { bindActionCreators } from 'redux';
// import { connect } from 'react-redux';

import $ from 'jquery';
import _ from 'lodash';

// import * as AccountActions from '../actions/Accounts';
// import * as FlashNotificationActions from '../actions/FlashNotifications';

import HeaderButtons from '../components/HeaderButtons';
import ColorPicker from '../components/ColorPicker';
import Toggle from '../components/Toggle';
import Switch from '../components/Switch';
import PositionInput from '../components/PositionInput';
import AlignmentInput from '../components/AlignmentInput';
import PageSettingsVisualDisplay from '../components/PageSettingsVisualDisplay';
import Tooltip from '../components/Tooltip';
import { NameInput, CSSInput } from '../components/SimpleInputs';
import WysiwygInput from './WysiwygInput';
import IconSelector from '../components/IconSelector';
import SectionHeader from '../components/SectionHeader';

import { AuthSubmitButton } from './Buttons';
import { FontSizeSlider } from './Sliders';

import SingleImageUploader from './SingleImageUploader';

import { generatePageDisplaySettings } from '../utils';

export default class DisplaySettingsForm extends Component {
  constructor(props) {
    super(props);

    this.pageDisplaySettings = generatePageDisplaySettings(props);
    this.state = { ...this.pageDisplaySettings, ...props.settings }
    this.setInitialState();

    this.scroll = _.throttle(this.scroll.bind(this), 0, { leading: true, trailing: true });
  }

  // componentDidMount() {
  //   $('.foreground').on('scroll.account', this.scroll);
  //   this.scroll();
  // }

  // componentWillUnmount() {
  //   $('.foreground').off('scroll.account', this.scroll)
  // }

  scroll() {
    let $el = $('.account-display .visual-display').first();
    
    let zoom = $('html').css('zoom') || 1;
    zoom = parseFloat(zoom);

    let t = $('form.account-settings')[0].getBoundingClientRect().y - $('.foreground')[0].getBoundingClientRect().y * zoom;
    let st = 0;

    let b = $('form.account-settings').height();
    let atBottom = ((st - t) + $el.height()) >= b;

    if ( (t) < 10 && !atBottom ) {
      $el.css('transform', `translateY(${-t + 20}px)`);
    } else if (atBottom) {
      $el.css('transform', `translateY(${b - $el.height()}px)`);
    } else {
      $el.css('transform', `translateY(0px)`);
    }  
  }

  setInitialState() {
    const settings = this.props.settings || this.pageDisplaySettings;
    this.initialState = { ...this.state, content: _.cloneDeep(settings.content) };

    setTimeout(() => {
      this.forceUpdate();
    }, 1000);
  }

  componentDidUpdate(prevProps, prevState) {
    console.log('component did update');
  }

  onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    this.updateSettings();
  }

  onContentChange(e) {
    const state = this.state;
    state.content[e.target.name] = e.target.value;
    this.setState(state);
  }

  onValidate(e) {
    console.log('on validate');
  }

  setRef() {
    console.log('set ref called');
  }

  updateSettings() {
    const account = {};
    account._id = this.props.account._id;
    account.pageDisplaySettings = { ...this.state };

    const formData = new FormData();

    if (this.state.localLogoImage) {
      formData.append('logoImage', this.state.localLogoImage);
      delete account.pageDisplaySettings.localLogoImage;
    }
    if (this.state.localBackgroundImage) {
      formData.append('backgroundImage', this.state.localBackgroundImage);
      delete account.pageDisplaySettings.localBackgroundImage;
    }

    formData.append('account', JSON.stringify(account));

    this.props.updateAccountPageDisplaySettings(account._id, formData, 'Your changes have been saved.').then(() => this.setInitialState());
  }

  cancel() {
    console.log('here');
    console.log(this.initialState);
    this.setState({ ...this.initialState });
    this.forceUpdate();
    this.props.flash('Your changes have been cancelled.');
  }

  onLogoImageUpload(image) {
    if (!image) {
      this.setState({ 
        logoImage: undefined,
        localLogoImage: undefined
      });
    } else {
      this.setState({
        localLogoImage: image,
        logoImage: image.url
      });
    }
  }

  onBackgroundImageUpload(image) {
    if (!image) {
      this.setState({ 
        backgroundImage: undefined,
        localBacgroundImage: undefined
      });
    } else {
      this.setState({
        localBackgroundImage: image,
        backgroundImage: image.url
      });
    }
  }

  render() {
    const hasChanged = JSON.stringify(this.state) !== JSON.stringify(this.initialState);

    let title = (<SectionHeader
      title=<span><i className="fas fa-link"/>Page Design Settings</span>
      subtitle="Control the appearance of your share page."
      className="no-margin center"
    />
  );
    if (this.props.hideTitle) {
      title = null;
    }

    let cssInput = null;
    if (this.state.useCustomCSS) {
      cssInput = (
        <CSSInput
          style={{ marginTop: 25 }}
          disabled={ !this.state.useCustomCSS }
          onChange={(value) => this.setState({ customCSS: value })}
          label="Custom CSS"
          moreInfo="This CSS will be included in your survey wherever it is rendered."
          type="text"
          placeholder="* { background: red; }"
          name="customCSS"
          onValidate={() => console.log('validate')}
          noValidate={true}
          value={this.state.customCSS}
        />
      );
    }

    return (
      <div className="account-display" style={this.props.style || {}}>
        { title }
        <div className="split">
          <div>
            <form 
              onSubmit={this.onSubmit.bind(this)}
              className={`account-settings ${this.props.loading ? 'loading' : ''}`}
            >

              <div className="card">
                <div className="card-divider" style={{ marginTop: -15 }}>
                  <h6>Style</h6>
                </div>

                <div>
                  <label>Page Style</label>
                  <div className="subtle">Choose a style that suits your brand.</div>

                  <div className="inline-toggle">
                    <Switch 
                      options={[
                        { label: 'Wave', value: 'wave' },
                        { label: 'Simple', value: 'simple' }
                      ]}
                      value={this.state.style}
                      onChange={(value) => {
                        this.setState({ style: value });
                      }}
                    />
                  </div>
                </div>
                <div className="card-divider">
                  <h6>Color</h6>
                </div>

                <div style={{zIndex: 9, position: 'relative'}}>
                  <label>Background Color</label>
                  <div className="subtle">Used in the background of your survey page.</div>
                  <ColorPicker
                    color={this.state.backgroundColor}
                    onChange={(color, event) => {
                      this.setState({ backgroundColor: color.hex });
                    }}
                  />
                </div>

                <div style={{zIndex: 8, position: 'relative'}} className={`${this.state.style === 'simple' ? 'disabled' : ''}`}>
                  <label>Foreground Color</label>
                  <div className="subtle">Used in the foreground of your survey page.</div>
                  <ColorPicker
                    color={this.state.foregroundColor}
                    onChange={(color, event) => {
                      this.setState({ foregroundColor: color.hex });
                    }}
                  />
                </div>

                <div className="card-divider">
                  <h6>Branding</h6>
                </div>

                <div>
                  <label>Logo<Tooltip bottom={true}>Image should be 800x300 pixels It will be resized automatically on upload.</Tooltip> <span>optional</span></label>
                  <div className="subtle">Add your logo on the top left of the page.</div>

                  <SingleImageUploader
                    hideLabel={true}
                    key={this.state.logoImage}
                    image={this.state.logoImage}
                    onChange={this.onLogoImageUpload.bind(this)} />
                </div>

                <div>
                  <label>Background Image <span>optional</span></label>
                  <div className="subtle">Add a custom background image.</div>

                  <SingleImageUploader
                    hideLabel={true}
                    key={this.state.backgroundImage}
                    image={this.state.backgroundImage}
                    onChange={this.onBackgroundImageUpload.bind(this)} />
                </div>

                <div className="card-divider">
                  <h6>Advanced</h6>
                </div>

                <div>
                  <label>Custom CSS<Tooltip>You can only use custom CSS if you subscribe to a <strong>Pro</strong> plan or better.</Tooltip></label>
                  <div className="inline-toggle">
                    <Toggle 
                      active={this.state.useCustomCSS}
                      disabled={this.props.account.plan.forceBranding}
                      onChange={(value) => {
                        this.setState({ useCustomCSS: value });
                      }}
                    />
                    <div className="subtle">Apply custom CSS to the page.</div>
                  </div>
                </div>

                { cssInput }

                {/*
                <div className="card-divider">
                  <h6>Widget</h6>
                </div>

                <div>
                  <label>Logo<Tooltip>Image should be 800x300 pixels It will be resized automatically on upload.</Tooltip> <span>optional</span></label>
                  <div className="subtle">Use an image for your logo.</div>
                </div>
                */}
                <div className={`actions ${hasChanged ? 'active' : ''}`}>
                  <AuthSubmitButton 
                    title="Save Changes"
                    disabled={!hasChanged}
                  />
                  <button 
                    className="cancel"
                    disabled={!hasChanged}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.cancel();
                    }}
                  >Cancel</button>
                </div>
              </div>
            </form>
          </div>

          <PageSettingsVisualDisplay
            pageDisplaySettings={this.state}
            poll={this.props.poll}
            account={this.props.account}
          />
        </div>

        <HeaderButtons>
          <button 
            disabled={!hasChanged}
            onClick={this.onSubmit.bind(this)}>{ this.props.loading ? 'Saving...' : 'Save Changes' }</button>

          <button 
            className="cancel"
            disabled={!hasChanged}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.cancel();
            }}
          >Cancel</button>
        </HeaderButtons>
      </div>
    );
  }
}
