// These must be the first lines in src/index.js
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';

import $ from 'jquery';

import store from './store';
import history from './store/history';

import { ConnectedRouter } from 'connected-react-router';
import { CookiesProvider } from 'react-cookie';

// import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';

import Router from './pages/Router';

import './scss/Index.css';

import { StripeProvider } from 'react-stripe-elements';

import { stripeApiKey } from './settings';

import ReactGA from 'react-ga'

const url = `${window.location.pathname}${window.location.search}`;
ReactGA.initialize('UA-135456585-2');
ReactGA.pageview(url);
window.curUrl = url;

history.listen(location => {
  const url = `${location.pathname}${location.search}`
  window.prevUrl = window.curUrl;
  window.curUrl = url;
  ReactGA.set({ page: url })
  ReactGA.pageview(url);
});

/* Remove console log in production TMP */
function noop() {}

if (process.env.NODE_ENV === 'production') {
  console.log = noop;
  console.warn = noop;
  console.error = noop;
}

ReactDOM.render(
  <Provider store={store}>
    <StripeProvider apiKey={stripeApiKey}>
      <CookiesProvider>
        <ConnectedRouter history={history}>
          <Router />
        </ConnectedRouter>
      </CookiesProvider>
    </StripeProvider>
  </Provider>,
  document.getElementById('root')
);

// registerServiceWorker();
unregister()

// add these lines
if (module.hot && process.env.NODE_ENV !== 'production') {
  module.hot.accept();
}