import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ColumnLoading from './ColumnLoading';

import ColumnLayout from '../components/ColumnLayout';
import HeaderButtons from '../components/HeaderButtons';

import SlideInfo from '../components/SlideInfo';
import Tooltip from '../components/Tooltip';
import Diff from '../components/Diff';
import ExportCsvModal from '../components/ExportCsvModal'
import PollInfo from '../components/PollInfo';
import VisibilityToggle from '../components/VisibilityToggle';

import * as DashboardActions from '../actions/Dashboard';
import * as ParticipantsActions from '../actions/Participants';
import * as PollActions from '../actions/Polls';

import { decode, encode, renderNumber, renderAccountTitle, getSentimentClass, getSentimentTitle } from '../utils';

import qs from 'query-string';

class PollCard extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps._id && (prevProps._id !== this.props._id)) {
      this.props.setIdx(0);
    }
  }

  next() {
    this.props.setIdx(this.props.idx + 1)
  }

  prev() {
    this.props.setIdx(this.props.idx - 1)
  }

  renderSlide() {
    const props = this.props;

    /* Poll Summary Card */
    if (this.props.idx === 0) {
      return (<PollInfo {...props} />)
    }

    const slide = this.props.slides[this.props.idx - 1];
    return (<SlideInfo slide={slide} diffs={this.props.diffs} slideIdx={this.props.idx} slideCount={this.props.slides.length} accountId={this.props.accountId} pollId={this.props._id} />)
  }  

  render() {
    const loading = (
      <div className="poll-card loading">
        <div>
          <div className="poll-content">
            <div className="section">
              <p>Loading...</p>
            </div>
          </div>
        </div>
      </div>
    );

    if (this.props.slides && this.props.idx !== 0 && !this.props.slides[this.props.idx-1]) {
      this.props.setIdx(0);
      return loading;
    }

    if (!this.props._id) {
      return loading;
    }

    const props = this.props;

    let editLink = `/a/${encode(props.accountId)}/p/${encode(props._id)}?tab=general`;
    const isVisible = props.isVisible !== false;

    let next = null;

    if (this.props.idx !== props.slides.length) {
      next = (<button onClick={this.next.bind(this)} className="next">Next</button>);
    }

    let back = null;
    if (this.props.idx !== 0) {
      back = (<button onClick={this.prev.bind(this)} className="back">Back</button>);
    }

    if (this.props.idx !== 0) {
      editLink = `/a/${encode(props.accountId)}/p/${encode(props._id)}/s/${encode(this.props.slides[this.props.idx-1]._id)}?tab=details`
    }

    return <div
      className={`poll-card poll-dashboard ${isVisible ? 'active' : 'inactive' }`}
    >
      <div>

        <div className="actions">
          { next }
          <Link className="edit-link" to={editLink}>Edit {this.props.idx === 0 ? 'Survey' : 'Slide'}</Link>
          { back }
        </div>

        <div className="poll-content">
          { this.renderSlide() }
        </div>

        <div className="actions footer">
          { next }
          { back }
        </div>
      </div>
    </div>
  }
};

class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.fetchPollData(this.props.pollId);
  }

  componentDidUpdate(prevProps) {
    if (this.props.pollId !== prevProps.pollId) {
      return this.props.fetchPollData(this.props.pollId);
    }
  }

  export() {
    this.setState({ showExportModal: true });
  }

  render() {
    let account;
    let loaded;

    if (!this.props.poll || !this.props.account) {
      return <ColumnLoading />
    }

    let breadcrumbs = (
      <div id="breadcrumbs">
        <div className="wrapper">
          <ul className="crumbs">
            <li><Link to={`/a/${encode(this.props.account._id)}`} title='Account'>Account</Link></li>
          </ul>
        </div>
      </div>
    );

    breadcrumbs = null;

    const pollDiff = this.props.diffs[this.props.poll._id] || {};

    const sentimentStats = (
      <div className="sentiment">
        <label>Sentiment<Tooltip>The average sentiment of answers to this survey.</Tooltip></label>
        <p title={this.props.poll.responseCount || 0} className={getSentimentClass(this.props.poll.sentiment)}>{getSentimentTitle(this.props.poll.sentiment)}</p>
      </div>
    );

    return (
      <ColumnLayout
        title="Survey → Dashboard"
        className="dashboard"
        graphics={false}
        breadcrumbs={breadcrumbs}
        pageType="poll-dashboard"
      >

        <div className={`account-stats no-request-counter ${sentimentStats ? 'wide' : ''}`}>
          <div className="card-subtitle center">Survey Stats<Tooltip bottom={true}>These numbers are for your survey as a whole. If you want to see how each slide is performing, click through below.</Tooltip></div>
          <div className="card">
            <div className="stats">
              <div className="response-count">
                <label><div className="pill">open-ended</div>Responses<Tooltip>All the open-ended responses you have received under this survey.</Tooltip></label>
                <Link to={`/responses/a/${encode(this.props.account._id)}/p/${encode(this.props.poll._id)}`}><p title={this.props.poll.responseCount || 0}>{ renderNumber(this.props.poll.responseCount, '--') }<Diff value={pollDiff.responsesDiff} /></p></Link>
              </div>
              <div className="vote-count">
                <label><div className="pill">fixed-choice</div>Responses<Tooltip>Total number of fixed-choice responses submitted to this survey.</Tooltip></label>
                <Link to={`/responses/a/${encode(this.props.account._id)}/p/${encode(this.props.poll._id)}`} ><p title={this.props.poll.voteCount || 0}>{ renderNumber(this.props.poll.voteCount, '--') }<Diff value={pollDiff.votesDiff} /></p></Link>
              </div>
              <div className="email-count">
                <label>Emails<Tooltip>All the emails you have collected with this survey.</Tooltip></label>
                <Link to={`/emails/a/${encode(this.props.account._id)}/p/${encode(this.props.poll._id)}`}><p title={this.props.poll.emailCount || 0}>{ renderNumber(this.props.poll.emailCount, '--') }<Diff value={pollDiff.emailsDiff} /></p></Link>
              </div>
              <div className="participant-count">
                <label>Participants<Tooltip>All the participants who have responded this survey.</Tooltip></label>
                <Link to={`/participants/a/${encode(this.props.account._id)}/p/${encode(this.props.poll._id)}`}><p title={this.props.poll.participantCount || 0}>{ renderNumber(this.props.poll.participantCount, '--') }<Diff value={pollDiff.participantsDiff} /></p></Link>
              </div>
              { sentimentStats }
            </div>
          </div>
        </div>

        <div className="dashboard-container" style={{ minHeight: '0px' }}>
          <div style={{ margin: '0px auto', width: '100%' }}>
            <div className="card-subtitle" style={{ textAlign: 'center' }}>Survey Results</div>
            <PollCard idx={this.props.idx} charts={this.props.charts} diffs={this.props.diffs} setIdx={this.props.setIdx} accountId={this.props.accountId} account={this.props.account} fetchPollWithSlides={this.props.fetchPollWithSlides} updateInsights={this.props.updateInsights} {...this.props.activePoll} />
          </div>
        </div>

        <HeaderButtons>
          <button
            onClick={this.export.bind(this)}
            disabled={this.props.activePoll.participantCount ? false : true}
          >Export to CSV</button>
        </HeaderButtons>

        <ExportCsvModal
          show={this.state.showExportModal}
          onCancel={() => this.setState({ showExportModal: false })}
          onConfirm={({ email, responsePollId, dateRange }) => {
            this.props.exportPollParticipants(email, responsePollId, dateRange);
          }}
          pollId={this.props.pollId}
          dashboardExport={true}
          title={'Export Survey'}
          subtitle={'This will export all participants who have ever interacted with this survey.'}
          copy={'The CSV will be sent to the email listed below. It can take up to 1 hour to recieve a CSV export. The duration will depend on the amount of data your account has accumulated.'}
          email={this.props.user.email}
        />
      </ColumnLayout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const pollId = decode(ownProps.match.params.pollId);

  const accounts = state.accounts || {};
  const account = accounts[accountId];

  const polls = state.polls || [];
  let poll = polls[pollId];
  if (typeof poll !== 'object') {
    poll = undefined;
  }

  const params = qs.parse(ownProps.location.search);
  const idx = parseInt(params.idx) || 0;

  return {
    account,
    accounts,
    accountId,
    pollId,
    poll,
    activePoll: state.polls[pollId],
    polls: state.polls,
    user: state.user,
    diffs: state.diffs,
    charts: state.charts,
    idx
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...PollActions, ...DashboardActions, ...ParticipantsActions }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
