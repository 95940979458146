import React, { Component } from 'react';

import MonthlySubmissionCounter from './MonthlySubmissionCounter';
import MonthlyRequestCounter from './MonthlyRequestCounter';

export default class MonthlyCounter extends Component {
  render() {
    if (!this.props.account || !this.props.account.plan) { return null; }

    const submissionLimit = this.props.account.plan.submissionLimit;
    if (submissionLimit) {
      return <MonthlySubmissionCounter style={this.props.style} account={this.props.account} inline={this.props.inline} hideHeader={this.props.hideHeader} />
    }
    return <MonthlyRequestCounter account={this.props.account} hideHeader={this.props.hideHeader} />
  }
}