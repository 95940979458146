import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { url } from '../settings';

import _ from 'lodash';

import * as AccountActions from '../actions/Accounts';
import * as ActivitiesActions from '../actions/Activities';
import * as FlashNotificationActions from '../actions/FlashNotifications';

import ColumnLoading from './ColumnLoading';

import ColumnLayout from '../components/ColumnLayout';
import Pagination from '../components/Pagination';

import SectionHeader from '../components/SectionHeader';
import AuthForm from '../components/AuthForm';
import LoadingIndicator from '../components/LoadingIndicator';

import { decode, encode, getDate, getDateAsTimestamp, isUrl, truncate, processMetadataForDisplay } from '../utils';

function getPhrase(trigger) {
  if (trigger === 'shopify-order-webhook') {
    return 'a Shopify order webhook'
  } else if (trigger === 'manual-campaign') {
    return 'a manual email campaign';
  } else if (trigger === 'csv-campaign') {
    return 'a csv email campaign';
  } else if (trigger === 'shopify-segment-campaign') {
    return 'a Shopify segment email campaign';
  } else if (trigger === 'shopify-abandoned-checkout') {
    return 'a Shopify abandoned checkout'      
  } else {
    return '';
  }
}

function renderResponses(activity) {
  return activity.responses.map((response) => {
    return (
      <div className="inline-row">
        <div className="top"><Link to={`/a/${encode(activity.accountId)}/p/${encode(activity.pollId)}/s/${encode(response.slideId)}`}>{response.key}</Link></div>
        <div>{response.value}</div>
      </div>
    )
  })
}

class PollFilter extends Component {
  onChange(e) {
    e.preventDefault();
    e.stopPropagation();

    const val = e.target.value;
    this.props.onChange('pollId', val);
  }

  render() {
    const { polls } = this.props;

    return (<div className="poll-filter">
      <div className="select-wrapper high-contrast"><select onChange={this.onChange.bind(this)}>
        <option value="">All Surveys</option>
        { polls.map((poll) => {
          return (<option value={poll._id} selected={this.props.pollId === poll._id}>{ poll.title }</option>)
        })}
      </select></div>
    </div>)
  }
}

class ActivityTypeFilter extends Component {
  onChange(e) {
    e.preventDefault();
    e.stopPropagation();

    const val = e.target.value;
    this.props.onChange('activityType', val);
  }

  render() {
    return (<div className="poll-filter">
      <div className="select-wrapper high-contrast"><select onChange={this.onChange.bind(this)}>
        <option selected value="">All Activity</option>
        <option value='submitted-responses'>Customer Responses</option>
        <option value='sent-email'>Outbound Emails</option>
        <option value='sent-sms'>Outbound SMS</option>
      </select></div>
    </div>)
  }
}


class Activity extends Component {
  constructor(props) {
    super(props);
    this.state = { showMore: false };
  }

  onBodyClick(e) {
    if (this.state.showMore) { return; }
    this.setState({ showMore: true });
  }

  render() {
    const { activity, account } = this.props;
    const poll = _.find(account.polls, ({_id}) => _id === activity.pollId) || {};

    const processedMetadata = processMetadataForDisplay(activity.metadata);

    let showMore = (<div className="show-more" onClick={() => this.setState({ showMore: !this.state.showMore })}>
      { this.state.showMore ? <span><i className="fas fa-minus" />Hide details</span> : <span><i className="fas fa-plus" />Show details</span> }
    </div>);

    let pollTitle = <span>[Removed]</span>
    if (poll.title) {
      pollTitle = <Link to={`/a/${encode(activity.accountId)}/p/${encode(activity.pollId)}`}>{ poll.title }</Link>;
    }
    let pollHeader = (
      <div className="poll"><i className="fas fa-chart-pie" />Survey: { pollTitle }</div>
    );

    let keys = Object.keys(processedMetadata);
    keys = _.sortBy(keys, (key) => {
      if (key.indexOf('shopify') !== -1) { return 3 };
      if (key.indexOf('Timestamp') !== -1) { return -1 };
      if (key.indexOf('Participant') !== -1) { return -1 };
    });

    if (activity.type === 'submitted-responses') {
      let info = null;
      if (this.state.showMore) {
        info = (
        <div className="info">
          { pollHeader }
          <div className="responses">
            <div className="heading-tag"><i className="fas fa-comment-alt" />Responses</div>
            { renderResponses(activity) }
          </div>

          <div className="metadata">
            <div className="heading-tag"><i className="fas fa-asterisk" />Metadata</div>
            { keys.map((key) => {
              if (!key) { return null };
              let val = activity.metadata[key];              

              if (key === 'activityId'){
                return null;
              }

              if (key === 'shopify_order_id' && this.props.account.shop) {
                val = <a target="_blank" rel="noopener noreferrer" href={`https://${this.props.account.shop}/admin/orders/${val}`}>{val}</a>
              } else if (key === 'shopify_customer_id' && this.props.account.shop) {
                val = <a target="_blank" rel="noopener noreferrer" href={`https://${this.props.account.shop}/admin/customers/${val}`}>{val}</a>
              } else {
                val = isUrl(val) ? <a href={val} title={val} target="_blank">{truncate(val, 60)}</a> : val;
              }

              let icon = null;
              if (key.indexOf('shopify') !== -1) {
                icon = <i className="fa-brands fa-shopify" />
              }
              return (
                <div className="inline-row">
                  <div className="top">{icon}{key}</div>
                  <div>{ val }</div>
                </div>
              )
            }) }
            <div className="inline-row">
              <div className="top">Participant</div>
              <div><a href={`/participants/a/${encode(activity.accountId)}/pa/${activity.participantId}?useId=true`} target="_blank">{activity.participantId}</a></div>
            </div>
            <div className="inline-row">
              <div className="top">Timestamp</div>
              <div>{getDateAsTimestamp(activity._id)}</div>
            </div>
          </div>
        </div>
        );
      }
      return <div className={`activity ${activity.type} ${activity.status}`} onClick={this.onBodyClick.bind(this)}>
        { showMore }
        <div className="header"><i className="fas fa-bell" />A response to <strong>{ poll.title || '[Removed]' }</strong> was submitted <strong>{ getDate(activity._id) }</strong></div>
        { info }
      </div>
    } else if (activity.type === 'sent-email') {
      let info = null;
      let responses = null;
      let sendAgain = null;
      if (activity.status === undefined) {
        sendAgain = <div>
          This survey has been sent <strong>{ this.props.activity.resendCount ? this.props.activity.resendCount+1 : 1 }</strong> time{ this.props.activity.resendCount ? 's' : '' }.
          <div className={`send-again ${this.props.activity.loading || this.props.activity.resendCount ? 'disabled' : ''}`} onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            if (this.props.activity.loading || this.props.activity.resendCount) { return; }

            this.props.resend(this.props.activity._id);
          }}><i className="fas fa-envelope" />Send again</div>
        </div>
      }
      if (activity.responses) {
        responses = (
          <div className="responses">
            <div className="heading-tag"><i className="fas fa-comment-alt" />Responses</div>
            { renderResponses(activity) }
          </div>
        );
      }
      if (this.state.showMore) {
        info = (
          <div className="info">
            { pollHeader }

            <div className="parameters">
              <div className="heading-tag"><i className="fas fa-at" />Email Parameters</div>
              <div className="inline-row">
                <div className="top">Email</div>
                <div>{ activity.email }</div>
              </div>
              <div className="inline-row">
                <div className="top">Survey Url</div>
                <div><a href={activity.url} target="_blank">{ truncate(activity.url, 60) }</a></div>
              </div>
            </div>

            <div className="metadata">
              <div className="heading-tag"><i className="fas fa-asterisk" />Metadata</div>
              { Object.keys(activity.metadata).map((key) => {
                if (!key) { return null };
                let val = activity.metadata[key];

                if (key === 'activityId'){
                  val = activity.status;
                  key = 'status'
                }

                return (
                  <div className="inline-row">
                    <div className="top">{key}</div>
                    <div>{isUrl(val) ? <a href={val} target="_blank">{truncate(val, 60)}</a> : val }</div>
                  </div>
                )
              }) }
              <div className="inline-row">
                <div className="top">timestamp</div>
                <div>{getDate(activity._id)}</div>
              </div>
            </div>

            { responses }
            { sendAgain }
          </div>
        );
      }
      return <div className={`activity ${activity.status}`} onClick={this.onBodyClick.bind(this)}>
        { showMore }
        <div className="header"><i className="fas fa-envelope" />An email was sent <strong>{ getDate(activity._id) }</strong> to <strong>{ activity.email }</strong> for survey <strong>{ poll.title || '[Removed]' }</strong> and was sent via <strong>{ getPhrase(activity.trigger) }</strong></div>
        { info }
      </div>
    } else if (activity.type === 'sent-sms') {
      let info = null;
      let responses = null;
      let sendAgain = null;
      if (activity.status === undefined) {
        sendAgain = <div>
          This survey has been sent <strong>{ this.props.activity.resendCount ? this.props.activity.resendCount+1 : 1 }</strong> time{ this.props.activity.resendCount ? 's' : '' }.
          <div className={`send-again ${this.props.activity.loading || this.props.activity.resendCount ? 'disabled' : ''}`} onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            if (this.props.activity.loading || this.props.activity.resendCount) { return; }

            this.props.resend(this.props.activity._id);
          }}><i className="fas fa-envelope" />Send again</div>
        </div>
      }
      if (activity.responses) {
        responses = (
          <div className="responses">
            <div className="heading-tag"><i className="fas fa-comment-alt" />Responses</div>
            { renderResponses(activity) }
          </div>
        );
      }
      if (this.state.showMore) {
        info = (
          <div className="info">
            { pollHeader }

            <div className="parameters">
              <div className="heading-tag"><i className="fas fa-at" />SMS Parameters</div>
              <div className="inline-row">
                <div className="top">Number</div>
                <div>{ activity.number }</div>
              </div>
              <div className="inline-row">
                <div className="top">Survey Url</div>
                <div><a href={activity.url} target="_blank">{ truncate(activity.url, 60) }</a></div>
              </div>
            </div>

            <div className="metadata">
              <div className="heading-tag"><i className="fas fa-asterisk" />Metadata</div>
              { Object.keys(activity.metadata).map((key) => {
                if (!key) { return null };
                const val = activity.metadata[key];
                return (
                  <div className="inline-row">
                    <div className="top">{key}</div>
                    <div>{isUrl(val) ? <a href={val} target="_blank">{truncate(val, 60)}</a> : val }</div>
                  </div>
                )
              }) }
              <div className="inline-row">
                <div className="top">timestamp</div>
                <div>{getDate(activity._id)}</div>
              </div>
            </div>

            { responses }
            { sendAgain }
          </div>
        );
      }
      return <div className={`activity ${activity.status}`} onClick={this.onBodyClick.bind(this)}>
        { showMore }
        <div className="header"><i className="fas fa-sms" />A SMS was sent <strong>{ getDate(activity._id) }</strong> to <strong>{ activity.number }</strong> for survey <strong>{ poll.title || '[Removed]' }</strong>.</div>
        { info }
      </div>

    }
  }
}

class Activities extends Component {
  constructor(props) {
    super(props);
    this.state = { pollId: this.props.pollId, activityType: undefined };
    props.fetchAccountAndActivities(this.state.pollId, undefined, 0);
  }

  onChange(key, value) {
    this.setState({ [key]: value }, () => {    
      this.props.fetchActivities(this.state.pollId, this.state.activityType, 0);
    });
  }

  render() {
    if (!this.props.activities || !this.props.account) {
      return <ColumnLoading />
    }

    let results = null;
    if (this.props.activities.data && this.props.activities.data.length) {
      results = (<span>
        <div className="activities-header">
          <Pagination
            showPagination={true}
            showNext={this.props.activities.page !== this.props.activities.pages}
            showPrev={this.props.activities.page !== 0}
            next={() => { this.props.fetchActivities(this.state.pollId, this.state.activityType, this.props.activities.page + 1); }}
            prev={() => { this.props.fetchActivities(this.state.pollId, this.state.activityType, this.props.activities.page - 1); }}
            curPage={this.props.activities.page + 1 }
            pageLength={this.props.activities.pages + 1}
          />

          <PollFilter
            polls={this.props.account.polls}
            onChange={this.onChange.bind(this)}
            pollId={this.state.pollId}
          />

          <ActivityTypeFilter
            onChange={this.onChange.bind(this)}
            activityType={this.state.activityType}
          />
        </div>

        <div style={{ wordBreak: 'break-word' }}className={`activities ${this.props.loading ? 'loading' : ''}`}>
          { this.props.activities.data.map((activity) => <Activity key={activity._id} activity={activity} account={this.props.account} resend={this.props.resend.bind(this)} />)}
        </div>
      </span>);
    } else if (this.state.pollId || this.state.activityType) {
      results = (
      <span>
        <div className="activities-header">
          <Pagination
            showPagination={this.props.activities.pages}
            showNext={this.props.activities.page !== this.props.activities.pages}
            showPrev={this.props.activities.page !== 0}
            next={() => { this.props.fetchActivities(this.state.pollId, this.props.activities.page + 1); }}
            prev={() => { this.props.fetchActivities(this.state.pollId, this.props.activities.page - 1); }}
            curPage={this.props.activities.page + 1 }
            pageLength={this.props.activities.pages + 1}
          />

          <PollFilter
            polls={this.props.account.polls}
            onChange={this.onChange.bind(this)}
            pollId={this.state.pollId}
          />

          <ActivityTypeFilter
            onChange={this.onChange.bind(this)}
            activityType={this.state.activityType}
          />
        </div>

        <div className="card empty-object-list emails">
          <h3>No Activity Yet</h3>
          <p>A list will show once someone has submitted responses or you if you have sent out any email campaigns.</p>
        </div>
      </span>);
    } else {
      results = (
        <div className="card empty-object-list emails">
          <h3>No Activity Yet</h3>
          <p>A list will show once someone has submitted responses or you if you have sent out any email campaigns.</p>
        </div>
      );
    }

    return (
      <ColumnLayout title="Activity" graphics={true}>

        <SectionHeader
          title=<span><i className="fas fa-paper-plane" />Activity</span>
          subtitle="View all your latest activity at a glance for your account."
          className="no-margin"
        />

        { results }

        <LoadingIndicator loading={this.props.loading} />

      </ColumnLayout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const accounts = state.accounts || {};
  const account = accounts[accountId];

  const pollId = decode(ownProps.match.params.pollId);
  const allActivities = state.activities || {};
  const activities = allActivities[accountId];

  return {
    accountId,
    account,
    pollId,
    activities,
    loading: state.activities.loading
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...AccountActions, ...ActivitiesActions,...FlashNotificationActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Activities);
