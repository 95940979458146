import React, { Component } from 'react';
import { pollTemplates, displayRulesTemplates } from '../settings';

import _ from 'lodash';

import Modal from './Modal';
import Embed from './Embed';
import Tooltip from './Tooltip';

import { getSlideLabel, getSlideIcon, decode } from '../utils';

function replaceProduct(str, product) {
  if (!str) { return str; }
  return str.replace(/{{ product }}/g, product);
}

class TemplateItem extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false, currentIdx: 0, prevIndexes: [] }
  }

  next() {
    this.state.prevIndexes.push(this.state.currentIdx);
    this.setState({ currentIdx: this.state.currentIdx + 1 });
  }

  prev() {
    const idx = this.state.prevIndexes.pop();
    if (idx !== undefined) {
      this.setState({ currentIdx: idx });
    }
  }

  showModal() {
    this.setState({ showModal: true });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  generateCopy(subtitle, copy) {
    let str = [];
    if (subtitle) {
      str.push(subtitle);
    }

    if (copy) {
      str.push(copy);
    }

    str = str.join(' ');

    if (!str) {
      return '';
    }
    return str.replace(/{{ product }}/g, `<strong>${this.props.product}</strong>`);
  }

  prepareSlides(slides) {
    const preparedSlides = [];
    slides.forEach((slide) => {
      const s = { ...slide }
      s.title = replaceProduct(s.title, this.props.product);
      s.copy = replaceProduct(s.copy, this.props.product);
      s.subtitle = replaceProduct(s.subtitle, this.props.product);
      preparedSlides.push(s);
    });

    return preparedSlides;
  }

  onSave(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.onChange({ target: { name: 'slidesTemplate', value: this.props.handle }});
    this.closeModal();
  }

  renderModal() {
    let customize = null;
    if (this.props.hasProduct) {
      customize = (
        <div className="section">
          <form>
            <label>Customize</label>
            <input
              type="text"
              name="product"
              value={this.props.product }
              onChange={this.props.onChange}
            />
          </form>
        </div>
      );
    }

    return (
      <Modal 
        isOpen={this.state.showModal}
        onRequestClose={this.closeModal.bind(this)}
      >
        <div className="frame template-selection">
          <div className="close" onClick={this.closeModal.bind(this)} />
          <div className="title">{ this.props.title }</div>

          <div className="split-content">
            <div className="left">
              <div className="section">
                { customize }

                <label>Survey Slides:</label>
                <ul>
                { this.props.slides.map(({ type, copy, title, subtitle, optional, hide }, idx) => {
                  if (hide) { return null; }
                  let optionalTag = null;

                  if (optional) {
                    optionalTag = <span className="type pill">Optional</span>
                  }

                  return (<li onClick={() => {
                    this.state.prevIndexes.push(this.state.currentIdx);
                    this.setState({ currentIdx: idx });
                  }} className={`${idx === this.state.currentIdx ? 'active' : ''}`}>{ optionalTag }<i className={`fas ${getSlideIcon(type)}`} /><div dangerouslySetInnerHTML={ { __html: this.generateCopy(subtitle, copy || title) } } /><div className="below">{getSlideLabel(type)}</div></li>);
                })}
                </ul>
              </div>

              <div className="preview"><a href={this.props.previewLink} target="_blank" rel="noopener noreferrer"><i className="fas fa-eye" />View Example</a></div>
            </div>
            <div className="right">
              <Embed 
                {...this.props.settings}
                toggleActive={() => { this.setState({ active: !this.state.active})}}
                delayRender={true}
                align="modal"
                createPoll={true}
                position={[0,0]}
                active={true}
                slides={this.prepareSlides(this.props.slides)}
                goto={(idx) => {
                  this.state.prevIndexes.push(this.state.currentIdx);
                  this.setState({ currentIdx: idx }) 
                }}
                next={this.next.bind(this)}
                prev={this.prev.bind(this)}
                currentIdx={this.state.currentIdx}
              />
            </div>
          </div>

          <div className="actions">
            <button className="positive" onClick={this.onSave.bind(this)}>Select Template</button>
            <button className="negative" onClick={this.closeModal.bind(this)}>Cancel</button>
          </div>
        </div>
      </Modal>
    );
  }

  render() {
    let selected;
    if (this.props.slidesTemplate === this.props.handle) {
      selected = <div className="selected"><i className="fas fa-check" />Selected</div>
    }

    return (
      <div className={`block ${ this.props.slidesTemplate === this.props.handle ? 'active' : ''}`}>
        <div onClick={this.showModal.bind(this)}>
          <div className="content">
            <div className="block-icon-wrapper" style={{ paddingTop: 15 }}>
              <i className={`block-icon fas ${this.props.icon || 'fa-theater-masks' }`} />
            </div>
            <p className="block-title"><div>{ this.props.title }</div></p>
            <p className="block-description">{ this.props.description }</p>
          </div>
          { selected }
        </div>

        { this.renderModal() }
      </div>
    );
  }
}

function filterPollTemplates(templates, query) {
  if (!query) {
    return templates;
  }

  let regex = new RegExp();
  try {
    regex = new RegExp(".*" + query + "+", 'i');
  } catch(err) {
    console.log(err);
  }

  return _.filter(templates, ({ handle, description, title, slides }) => {
    if (regex.test(title)) { return true; }
    if (regex.test(handle)) { return true; }
    if (regex.test(description)) { return true; }

    const slideString = _.flatten(slides.map(({ title, subtitle, copy }) => {
      return [ title, subtitle, copy ];
    })).join(', ');
    if (regex.test(slideString)) { return true; }

    return false;
  });
}

function filterDisplayRulesTemplates(templates, query) {
  if (!query) {
    return templates;
  }

  const regex = new RegExp(".*" + query + "+", 'i');

  return _.filter(templates, ({ handle, description, title, pageRuleDescriptions }) => {
    if (regex.test(title)) { return true; }
    if (regex.test(handle)) { return true; }
    if (regex.test(description)) { return true; }

    const pageRuleDescriptionsString = pageRuleDescriptions.join(', ');
    if (regex.test(pageRuleDescriptionsString)) { return true; }

    return false;
  });
}


export class TemplatesInput extends Component {
  constructor(props) {
    super(props);
    this.state = { showMore: false };
    // this.state = { product: this.props.product, slidesTemplate: this.props.slidesTemplate }
  }

  onChange(e) {
    this.props.onChange(e);
  }

  render() {
    let filteredPollTemplates = filterPollTemplates(pollTemplates, this.state.query);

    let showMore = <div className="show-more">
      { this.state.showMore === false && <div
        className="show-more-toggle"
        onClick={() => this.setState({ showMore: true })}
      ><i className="fas fa-plus" />Show More</div> }
    </div>

    if (this.state.query) {
      showMore = null;
    }

    let content = (
      <div className="blocks">
        { filteredPollTemplates.map((template, idx) => {
          if (idx > 9 && (this.state.showMore === false)) {
            return null;
          }
          return <TemplateItem
            { ...template }
            settings={this.props.settings}
            onChange={this.onChange.bind(this)}
            product={this.props.product}
            slidesTemplate={this.props.slidesTemplate}
          />
        })}

        { showMore }
      </div>
    );

    if (filteredPollTemplates.length === 0) {
      content = <div className="empty"><i className="fas fa-user-secret" />
        <div className="top">No matching templates found.</div>
        <div>Try a different search.</div>
      </div>
    }

    return <div className="blocks-input">
      <div>
        <label>Select a template</label>
        <p className="subtle">Click to preview each template before commiting. You can edit everything about the survey later on.</p>
      </div>

      <div className="filter">
        <i className="fas fa-search" />
        <input
          type="text"
          value={this.state.query}
          placeholder="Search templates"
          onChange={(e) => {
            e.preventDefault();
            e.stopPropagation();
            this.setState({ query: e.target.value });
          }}
        />
      </div>

      { content }
    </div>
  }
}

class DisplayRulesTemplateItem extends TemplateItem {
  constructor(props) {
    super(props);
    this.state = { showModal: false, emailDelay: props.emailDelay }
  }

  showModal() {
    this.setState({ emailDelay: this.props.emailDelay });
    this.setState({ showModal: true });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  onSave(e) {
    e.preventDefault();
    e.stopPropagation();

    this.props.onChange({ target: { name: 'emailDelay', value: this.state.emailDelay }});
    this.props.onChange({ target: { name: 'displayRulesTemplate', value: this.props.handle }});

    this.closeModal();
  }

  renderModal() {
    let shopifyDisclaimer = null;

    if (this.props.shopify && !this.props.isShopify) {
      shopifyDisclaimer = (
        <div className="shopify-disclaimer">This template was built for Shopify stores. If your website has a different url structure, it may not work.</div>
      );
    }

    let emailForm = null;
    if (this.props.type === 'email' || this.props.type === 'SMS') {
      let option = <option>Order Fulfilled</option>
      if (this.props.handle.indexOf('abandoned-checkout') !== -1) {
        option = <option value="abandoned-checkout">Abandoned Checkout</option>;
      }
      if (this.props.handle.indexOf('order-paid') !== -1) {
        option = <option value="abandoned-checkout">Order Paid</option>;        
      }
      if (this.props.handle.indexOf('order-cancel') !== -1) {
        option = <option value="abandoned-checkout">Order Cancelled</option>;        
      }
      if (this.props.handle.indexOf('order-delivered') !== -1) {
        option = <option value="order-delivered">Order Delivered</option>;        
      }
      emailForm = <div className="has-dynamic-input email-settings-form">
          <label>Trigger Settings<Tooltip>Adjust these settings in order to determine how and when your survey will be emailed to your customers.</Tooltip></label>
          <div className="inline-input" style={{ marginTop: 15, marginBottom: 25 }}>
            <span className="prefix">Send </span>
            <input
              style={{ width: 50, display: 'inline-block', padding: 0, margin: 0 }}
              type="text"
              name='emailDelay'
              value={this.state.emailDelay}
              onChange={(e) => {
                let val = e.target.value.replace(/[^\d.-]/g,'');
                // this.props.onChange({ target: { name: 'emailDelay', value: val }});
                this.setState({ emailDelay: val });
              }}
            />
            <span className="suffix">hour(s) after <select
              style={{width: 'auto', display: 'inline-block', textAlign: 'left'}}
              onChange={(e) => {
                e.preventDefault();
                e.stopPropagation();
                return false;
              }}
            >
              { option }
            </select></span>
          </div> 
        </div>

        if (this.props.handle === 'email-campaign') {
          emailForm = null;
        }
    }

    let shopifyExtensibilityNotice = null;
    if (this.props.showExtensibilityNotice && this.props.shopify) {
      shopifyExtensibilityNotice = <div className="shopify-extensibility-notice">
        <p className="title">If you <strong>are</strong> using <a href="https://help.shopify.com/en/manual/checkout-settings/customize-checkout-configurations/checkout-extensibility" target="_blank">checkout or customer account extensibility</a></p>
        <p>Make sure to add the app block to your thank you, order status, and customer profile pages.</p>
        <p style={{ marginTop: 5 }}><a href="https://www.zigpoll.com/blog/zigpoll-shopify-checkout-extensions" target="_blank">Click here for a step by step guide</a>.</p>
      </div>
    }


    return (
      <Modal 
        isOpen={this.state.showModal}
        onRequestClose={this.closeModal.bind(this)}
      >
        <div className={`frame template-selection ${this.props.page || ''}`}>
          <div className="close" onClick={this.closeModal.bind(this)} />
          <div className="title" dangerouslySetInnerHTML={{ __html: this.props.title }}/>

          <div className="content display-rules-content">
            <div className="section" style={{ marginBottom: 25 }}>
              <label>Description:</label>
              <div>{ this.props.description }</div>
            </div>

            <div className="section">
              <label>Display Rules:</label>
              <ul>
              { this.props.pageRuleDescriptions.map((rule) => {
                return (<li dangerouslySetInnerHTML={{ __html: rule }} />);
              })}
              </ul>
              { emailForm }
              { shopifyExtensibilityNotice }
            </div>

            { shopifyDisclaimer }
          </div>

          <div className="actions">
            <button className="positive" onClick={this.onSave.bind(this)}>Select Template</button>
            <button className="negative" onClick={this.closeModal.bind(this)}>Cancel</button>
          </div>
        </div>
      </Modal>
    );
  }

  render() {
    let shopify = null;
    let type = <span className="block-type">Web</span>

    if (this.props.type) {
      type = (<span className="block-type">{ this.props.type }</span>);
    }
    if (this.props.shopify) {
      shopify = (<div className="built-for-shopify"><img src="https://www.zigpoll.com/img/shopify_glyph.png" /></div>)
    }
    let selected;
    if (this.props.displayRulesTemplate === this.props.handle) {
      selected = <div className="selected"><i className="fas fa-check" />Selected</div>
    }
    let iconSuffix = null;
    if (this.props.type === 'email') {
      iconSuffix = <span><i className="fas fa-arrow-right arrow" /><i className="fas fa-envelope" /></span>      
    }
    if (this.props.type === 'SMS') {
      iconSuffix = <span><i className="fas fa-arrow-right arrow" /><i className="fas fa-sms" /></span>
    }
    if (this.props.handle.indexOf('with-cart') !== -1) {
      iconSuffix = <span><i className="fas fa-arrow-right arrow" /><i className="fas fa-cart-plus" /></span>
    }
    if (this.props.handle.indexOf('without-cart') !== -1) {
      iconSuffix = <span><i className="fas fa-arrow-right arrow" /><i className="fas fa-cart-arrow-down" /></span>
    }

    return (
      <div className={`block ${ this.props.displayRulesTemplate === this.props.handle ? 'active' : ''}`}>
        <div onClick={this.showModal.bind(this)}>
          <div className="content">
            { type }
            <div className="block-icon-wrapper">
              <i className={`block-icon fas ${this.props.icon || 'fa-theater-masks' }`} />
              { iconSuffix }
            </div>
            <p className="block-title"><div dangerouslySetInnerHTML={{ __html: this.props.title }}/></p>
            <p className="block-subtitle">{ this.props.description }</p>
            { shopify }
          </div>

          { selected }
        </div>

        { this.renderModal() }
      </div>
    );
  }
}

export class DisplayRulesTemplatesInput extends TemplatesInput {
  render() {
    let filteredDisplayRulesTemplates = filterDisplayRulesTemplates(displayRulesTemplates, this.state.query);

    let showMore = <div className="show-more">
      { this.state.showMore === false && <div
        className="show-more-toggle"
        onClick={() => this.setState({ showMore: true })}
      ><i className="fas fa-plus" />Show More</div> }
    </div>

    if (this.state.query) {
      showMore = null;
    }

    let idx = 0;
    let content = (<div className="blocks">
      { filteredDisplayRulesTemplates.map((template) => {
        if (template.createPollOnly && this.props.page !== 'create') { return null; }
        if (template.shopifyOnly && !this.props.isShopify) { return null; }

        ++idx;
        if (idx > 10 && (this.state.showMore === false)) {
          return null;
        }

        return <DisplayRulesTemplateItem
          { ...template }
          emailDelay={this.props.emailDelay}
          onChange={this.onChange.bind(this)}
          product={this.props.product}
          page={this.props.page}
          isShopify={this.props.isShopify}
          displayRulesTemplate={this.props.displayRulesTemplate}
        />
      })}

      { idx >= 10 && showMore }
    </div>);

    let filter = (
      <div className="filter">
        <i className="fas fa-search" />
        <input
          type="text"
          value={this.state.query}
          placeholder="Search templates"
          onChange={(e) => {
            e.preventDefault();
            e.stopPropagation();
            this.setState({ query: e.target.value });
          }}
        />
      </div>
    );

    let header = (
    <div>
      <label>Select a display template</label>
      <p className="subtle">Click to preview each template before commiting.</p>
    </div>
    )

    if (this.props.page !== 'create') {
      filter = null;
      header = null;
    }

    return <div className={`blocks-input ${this.props.page || ''}`}>
      { header }
      { filter }
      { content }
    </div>
  }
}
