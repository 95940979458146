export const PUSH_NOTIFICATION = "PUSH_NOTIFICATION";
export const POP_NOTIFICATION = "POP_NOTIFICATION";
export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS"

export const pushServerError = (message) => {
  return {
    type: PUSH_NOTIFICATION,
    notification: {
      type: 'server-error',
      message
    }
  }
}

export const pushNotification = (message) => {
  return {
    type: PUSH_NOTIFICATION,
    notification: {
      type: '',
      message
    }
  }
}

export const popNotification = () => {
  return {
    type: POP_NOTIFICATION,
  }
}

export const clearNotifications = (error) => {
  return {
    type: CLEAR_NOTIFICATIONS
  }
}
